var Reflux = require('reflux');
var SignupAPIUtils = require('../utils/SignupAPIUtils');

var SignupActions = Reflux.createActions({
  'requestCode': {asyncResult: true},
  'verifyCode': {asyncResult: true},
  'verifyAccessToken': {asyncResult: true},
  'acceptPrivacyPolicy': {asyncResult: true},
  'acceptTermsAndConditions': {asyncResult: true},
  'registerSeller': {asyncResult: true},
  'registerBuyer': {asyncResult: true},
  'registerConsultant': {asyncResult: true}
});

SignupActions.requestCode.listenAndPromise(SignupAPIUtils.requestCode);
SignupActions.verifyCode.listenAndPromise(SignupAPIUtils.verifyCode);
SignupActions.verifyAccessToken.listenAndPromise(SignupAPIUtils.verifyAccessToken);
SignupActions.acceptPrivacyPolicy.listenAndPromise(SignupAPIUtils.acceptPrivacyPolicy);
SignupActions.acceptTermsAndConditions.listenAndPromise(SignupAPIUtils.acceptTermsAndConditions);
SignupActions.registerSeller.listenAndPromise(SignupAPIUtils.registerSeller);
SignupActions.registerBuyer.listenAndPromise(SignupAPIUtils.registerBuyer);
SignupActions.registerConsultant.listenAndPromise(SignupAPIUtils.registerConsultant);

module.exports = SignupActions;
