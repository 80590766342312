var axios = require('axios');
var config = require('../config.js');

var ServiceAPIUtils = {

  getServices: function(parent) {
    if (typeof parent === 'undefined') {
      return axios.get(config.apiRoot + '/services');
    } else {
      return axios.get(config.apiRoot + '/services?parent=' + parent);
    }
  },

  createService: function(name, cost_account_code, parent) {
    return axios.post(config.apiRoot + '/services', {
      name: name,
      cost_account_code: cost_account_code,
      parent: parent
    });
  },

  getService: function(id) {
    return axios.get(config.apiRoot + '/services/' + id);
  },

  updateService: function(id, name, cost_account_code, parent) {
    return axios.put(config.apiRoot + '/services/' + id, {
      name: name,
      cost_account_code: cost_account_code,
      parent: parent
    });
  },

  deleteService: function(id) {
    return axios.delete(config.apiRoot + '/services/' + id);
  },

  getServiceCheckboxes: function() {
    return axios.get(config.apiRoot + '/service_checkboxes');
  }

};

module.exports = ServiceAPIUtils;
