var Reflux = require('reflux');
var AccountActions = require('../actions/AccountActions');

var SendPasswordResetStore = Reflux.createStore({

  listenables: [AccountActions],

  onSendPasswordResetCompleted: function (res) {
    this.trigger('SEND_PASSWORD_RESET_COMPLETED');
  },

  onSendPasswordResetFailed: function (res) {
    this.trigger('SEND_PASSWORD_RESET_FAILED');
  }

});

module.exports = SendPasswordResetStore;
