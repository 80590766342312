var React = require('react/addons');

var Contact = React.createClass({

  mixins: [React.addons.LinkedStateMixin],

  getDefaultProps: function() {
    return {
      contact: {
        first_name: null,
        last_name: null,
        phone: null,
        email: null
      },
      required: []
    };
  },

  getInitialState: function() {
    return {
      first_name: this.props.contact.first_name,
      last_name: this.props.contact.last_name,
      phone: this.props.contact.phone,
      email: this.props.contact.email
    };
  },

  componentDidMount: function() {
    this.props.required.forEach(function(row, index) {
      if (typeof this.refs[row] !== 'undefined') {
        $(this.refs[row].getDOMNode()).prop('required', true);
      }
    }, this);
  },

  render: function() {
    return (
      <div>
        <div className="row">
          <div className="col-sm-3">
            <div className="form-group">
              <label htmlFor="inputFirstName" className="control-label">First name {this.props.required.indexOf('first_name') !== -1 ? '*' : ''}</label>
              <input type="text" id="inputFirstName" className="form-control" placeholder="First name" valueLink={this.linkState('first_name')} ref="first_name" />
              <div className="help-block with-errors"></div>
            </div>
          </div>
          <div className="col-sm-3">
            <div className="form-group">
              <label htmlFor="inputLastName" className="control-label">Last name {this.props.required.indexOf('last_name') !== -1 ? '*' : ''}</label>
              <input type="text" id="inputLastName" className="form-control" placeholder="Last name" valueLink={this.linkState('last_name')} ref="last_name" />
              <div className="help-block with-errors"></div>
            </div>
          </div>
          <div className="col-sm-3">
            <div className="form-group">
              <label htmlFor="inputPhone" className="control-label">Phone {this.props.required.indexOf('phone') !== -1 ? '*' : ''}</label>
              <input type="tel" id="inputPhone" className="form-control" placeholder="Phone" valueLink={this.linkState('phone')} ref="phone" />
              <div className="help-block with-errors"></div>
            </div>
          </div>
          <div className="col-sm-3">
            <div className="form-group">
              <label htmlFor="inputEmail" className="control-label">Email {this.props.required.indexOf('email') !== -1 ? '*' : ''}</label>
              <input type="email" id="inputEmail" className="form-control" placeholder="Email" valueLink={this.linkState('email')} ref="email" />
              <div className="help-block with-errors"></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
});

module.exports = Contact;
