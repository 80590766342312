var Reflux = require('reflux');
var SignupActions = require('../actions/SignupActions');

var accessToken = null;

var VerifyAccessStore = Reflux.createStore({

  listenables: [SignupActions],

  getAccessToken: function() {
    return accessToken;
  },

  onVerifyCodeCompleted: function (res) {
    accessToken = res.data.accessToken;
    this.trigger('VERIFY_CODE_COMPLETED');
  },

  onVerifyCodeFailed: function (res) {
    this.trigger('VERIFY_CODE_FAILED');
  }

});

module.exports = VerifyAccessStore;
