var React = require('react/addons');
var Reflux = require('reflux');
var Router = require('react-router');
var Waypoint = require('react-waypoint');
var Navbar = require('../../infrastructure/components/Navbar');
var SignupActions = require('../actions/SignupActions');
var VerifyAccessTokenStore = require('../stores/VerifyAccessTokenStore');
var AcceptTermsAndConditionsStore = require('../stores/AcceptTermsAndConditionsStore');
var termsAndConditions = require('../../data/legal/terms_and_conditions');

var AcceptTermsAndConditions = React.createClass({

  mixins: [React.addons.LinkedStateMixin, Reflux.ListenerMixin, Router.Navigation],

  getInitialState: function() {
    return {
      alert: ''
    };
  },

  componentDidMount: function() {
    // Verify access token
    this.listenTo(VerifyAccessTokenStore, this.onVerifyAccessTokenStore);
    SignupActions.verifyAccessToken(this.props.params.accessToken);

    this.listenTo(AcceptTermsAndConditionsStore, this.onAcceptTermsAndConditionsStore);
  },

  onVerifyAccessTokenStore: function(status) {
    if (status === 'VERIFY_ACCESS_TOKEN_FAILED') {
      this.setState({alert: 'Invalid access token.'});
    }
  },

  onAcceptTermsAndConditionsStore: function(status) {
    if (status === 'ACCEPT_TERMS_AND_CONDITIONS_COMPLETED') {
      // transition to buyer or seller registration form
      this.transitionTo(AcceptTermsAndConditionsStore.getOrganizationType() + '-registration', {accessToken: this.props.params.accessToken});
    } else if (status === 'ACCEPT_TERMS_AND_CONDITIONS_FAILED') {
      this.setState({alert: 'Invalid access token.'});
    }
  },

  handleWaypointEnter: function(e) {
    $('.btn-agree').prop('disabled', false);
  },

  handleAgree: function(e) {
    e.preventDefault();
    SignupActions.acceptTermsAndConditions(this.props.params.accessToken);
  },

  render: function() {
    var alert;
    var _termsAndConditions;
    var content;

    alert = this.state.alert ? (
      <div className="alert alert-danger" role="alert">
        <i className="fa fa-exclamation-circle"></i> <strong>Error!</strong> {this.state.alert}
      </div>
    ) : '';

    _termsAndConditions = (
      <div>
        <p>To continue, you must agree to the AFEMax™ Web Site Terms and Conditions.</p>
        <div className="scrollable">
          <h2>AFEMax™ Web Site Terms and Conditions</h2>

          <div dangerouslySetInnerHTML={termsAndConditions()} />

          <Waypoint
            onEnter={this.handleWaypointEnter}
            threshold={0}
          />
        </div>
        <form onSubmit={this.handleAgree}>
          <button className="btn btn-lg btn-primary btn-agree" type="submit" disabled>Agree</button>
        </form>
      </div>
    );

    content = alert ? alert : _termsAndConditions;

    return (
      <div>
        <Navbar />

        <div className="title-banner">
          <div className="container">
            <h1>Sign up</h1>
          </div>
        </div>

        <div className="container main-container">
          <div className="row">
            <div className="col-md-12">
              <h3>Terms And Conditions</h3>
              {content}
            </div>
          </div>
        </div>

      </div>
    );
  }

});

module.exports = AcceptTermsAndConditions;
