var React = require('react/addons');
var DatePicker = require('../../shared/DatePicker');

var SafetyCertifications = React.createClass({

  mixins: [React.addons.LinkedStateMixin],

  getDefaultProps: function() {
    return {
      organization: {
        sc_comply_works: null,
        sc_isnet_world: null,
        sc_secor: null,

        sc_comply_works_expiry: null,
        sc_isnet_world_expiry: null,
        sc_secor_expiry: null
      }
    };
  },

  getInitialState: function() {
    return {
      sc_comply_works: this.props.organization.sc_comply_works_expiry !== null,
      sc_isnet_world: this.props.organization.sc_isnet_world_expiry !== null,
      sc_secor: this.props.organization.sc_secor_expiry !== null,

      sc_comply_works_expiry: this.props.organization.sc_comply_works_expiry,
      sc_isnet_world_expiry: this.props.organization.sc_isnet_world_expiry,
      sc_secor_expiry: this.props.organization.sc_secor_expiry
    };
  },

  handleExpiryChange: function(stateRef, value) {
    this.setState({[stateRef]: value});
  },

  render: function() {
    return (
      <div>
        <div className="row">
          <div className="col-sm-6">
            <div className="form-group">
              <div className="checkbox">
                <label>
                  <input type="checkbox" checkedLink={this.linkState('sc_comply_works')} /> Is your company registered with Comply Works?
                </label>
              </div>
            </div>
          </div>
          <div className="col-sm-2">
            <DatePicker
              label="Expiry date"
              required={this.state.sc_comply_works}
              value={this.state.sc_comply_works_expiry}
              stateRef="sc_comply_works_expiry"
              initialClear={true}
              onChange={this.handleExpiryChange} />
          </div>
        </div>

        <div className="row">
          <div className="col-sm-6">
            <div className="form-group">
              <div className="checkbox">
                <label>
                  <input type="checkbox" checkedLink={this.linkState('sc_isnet_world')} /> Is your company registered with IsNet World?
                </label>
              </div>
            </div>
          </div>
          <div className="col-sm-2">
            <DatePicker
              label="Expiry date"
              required={this.state.sc_isnet_world}
              value={this.state.sc_isnet_world_expiry}
              stateRef="sc_isnet_world_expiry"
              initialClear={true}
              onChange={this.handleExpiryChange} />
          </div>
        </div>

        <div className="row">
          <div className="col-sm-6">
            <div className="form-group">
              <div className="checkbox">
                <label>
                  <input type="checkbox" checkedLink={this.linkState('sc_secor')} /> Is your company registered with COR or SECOR?
                </label>
              </div>
            </div>
          </div>
          <div className="col-sm-2">
            <DatePicker
              label="Expiry date"
              required={this.state.sc_secor}
              value={this.state.sc_secor_expiry}
              stateRef="sc_secor_expiry"
              initialClear={true}
              onChange={this.handleExpiryChange} />
          </div>
        </div>
      </div>
    );
  }

});

module.exports = SafetyCertifications;
