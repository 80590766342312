module.exports = function() {
  return {
    __html: `
  <p>The Terms and Conditions related to the use of the AFEMax™ Web Site, (<a href="https://www.afemax.com">www.afemax.com</a>, the WEB SITE), together with the AFEMax™ Privacy Policy constitute the entire agreement between AFEMax™ and you pertaining to this Web Site, and supersede any and all written and oral agreements previously existing between us with respect to this Web Site.</p>

  <h3>General Disclaimer</h3>
  <p>The Web Site is provided "as is" and access and use by you is at your own risk. Although reasonable efforts are used to ensure that the Web Site will be current and will contain no errors or inaccuracies, no representations, warranties, guarantees or conditions (whether express or implied) are given as to the operation of this Web Site or that this Web Site and the information, content or materials included in this Web Site will be error free or completely accurate or current at all times, or at any time.</p>

  <h3>Ownership, Copyright &amp; Trademarks</h3>
  <p>All content, including without limitation, all text, design, graphics, drawings, photographs, code and software, and all organization and presentation of such content, which forms a part of this Web Site, are subject to intellectual property rights, including copyright and trade-marks held by or licensed to AFEMax™ All such rights are expressly reserved.</p>
  <p>You may view, copy electronically and print hard copies of pages from this Web Site for your own non-commercial and lawful use, if such copies clearly display the copyright and any other proprietary notices of AFEMax™ No other copying of this Web Site, completely or in part is permitted without the express written authorization of AFEMax™.
  </p>
  <p>Certain names, words, logos, slogans and images used on this Web Site are the property of and are subject to trademark rights held by AFEMax™ Certain other trademarks, trade names, words, logos, slogans and images listed on this Web Site are the property of their respective owners. Use of any such property, except as expressly authorized, shall constitute a violation of the rights of the owner of the property.</p>
  <p>Any rights not expressly granted herein are reserved.</p>

  <h3>Restrictions on Web Site Use</h3>
  <h5>Rules of Use:<br />Pertaining to the Web Site Use or any Content, you agree not to:</h5>
  <ol type="a">
    <li>use any device, software or technique to interfere with or attempt to interfere with the proper working of the Site</li>
    <li>post or transmit to the Site any unlawful, fraudulent, harassing, libelous, or obscene Information of any kind</li>
    <li>post or send to the Site any Information that contains a virus, bug, or other harmful item</li>
    <li>publish, perform, distribute, prepare derivative works, copy, reverse engineer, or use the Content (other than as expressly permitted herein); (v) post or transmit into or on the Site any Information in violation of another party's copyright or intellectual property rights.</li>
    <li>take any action which imposes an unreasonable or disproportionately large load on AFEMax™'s infrastructure.</li>
    <li>redeliver any of the Content using "framing", hyperlinks, or other technology without AFEMax™'s express written permission.</li>
    <li>use any device or technology to provide repeated automated attempts to access any password-protected portions of the Site</li>
  </ol>

  <h3>Monitoring:</h3>
  <p>You acknowledge that AFEMax™ has the right, but no obligation, to monitor the Web Site and to disclose any Information necessary to operate the Web Site, to protect AFEMax™, Others, and AFEMax™'s customers, and to comply with legal obligations or governmental requests. AFEMax™ reserves the right to refuse to post or to remove any Information on the Web Site, in completely or in part, for any reason.</p>

  <h3>Compliance With Law:</h3>
  <p>You agree to comply with all governmental laws, statutes, ordinances, and regulations (including unfair competition, anti-discrimination or false advertising) regarding your Web Site Use.</p>

  <h3>Web Site Linking</h3>
  <p>The Web Site may contain links to other Web Sites. All such other Web Sites are independent from this Web Site, from AFEMax™ AFEMax™ has no control over, and expressly disclaims any liability for such Web Sites or their contents. The provision of any link does not constitute an endorsement.</p>

  <h3>Warranty, Disclaimer</h3>
  <p>AFEMax™ AND ALL CONTENT PROVIDERS MAKE NO REPRESENTATION ABOUT THE SUITABILITY OF THE CONTENT HEREON. THIS WEB SITE, AND ACCESS TO ANY LINKED-WEB SITE, IS PROVIDED TO YOU BY ALL CONTENT PROVIDERS "AS IS" AND "AS AVAILABLE", WITH NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, EITHER EXPRESSED OR IMPLIED, INCLUDING, BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, ACCURACY, AND NON-INFRINGEMENT. YOU HEREBY DISCLAIM ALL WARRANTIES BY AFEMax™ RELATING TO YOUR WEB SITE USE. YOU ACKNOWLEDGE THAT YOUR ACCESS TO THE WEB SITE WILL NOT BE FREE OF INTERRUPTIONS, THAT THE INFORMATION HEREIN MAY CONTAIN BUGS, ERRORS, TECHNICAL INACCURACIES, PROBLEMS OR OTHER LIMITATIONS, AND THAT THE SITE MAY BE UNAVAILABLE FROM TIME TO TIME. YOU ASSUME TOTAL RESPONSIBILITY AND RISK FOR YOUR WEB SITE USE AND WEB SITE-RELATED SERVICES. </p>

  <h3>Limitation of Liability</h3>
  <p>UNDER NO CIRCUMSTANCES WILL AFEMax™ BE LIABLE OR RESPONSIBLE FOR ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL (INCLUDING DAMAGES FROM LOSS OF BUSINESS, LOST PROFITS, LITIGATION, OR THE LIKE), SPECIAL, EXEMPLARY, PUNITIVE OR OTHER DAMAGES, UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN ANY WAY RELATING TO THE WEB SITE, YOUR WEB SITE USE, OR THE CONTENT, EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.</p>

  <h3>Remedies:</h3>
  <p>YOUR SOLE REMEDY FOR DISSATISFACTION WITH THE WEB SITE AND/OR CONTENT IS TO CEASE ALL USE OF THIS WEB SITE.</p>
  <p>You may have additional rights under certain laws (including consumer laws) which do not allow the exclusion of implied warranties, or the exclusion or limitation of certain damages. If these laws apply to you, the exclusions or limitations in this Agreement that directly conflict with such laws may not apply to you.</p>

  <h3>Miscellaneous:</h3>
  <p>AFEMax™'s failure to insist upon or enforce strict performance of any provision of this Agreement shall not be construed as a waiver of any provisions or right. If any of the provisions contained in this Agreement be determined to be void, invalid or otherwise unenforceable by a court of competent jurisdiction, such determination shall not affect the remaining provisions contained herein.</p>

  <h3>Governing Law:</h3>
  <p>The laws of the Province of Alberta shall govern this Agreement. The parties hereto agree to the exclusive jurisdiction of the Alberta Provincial Court for all actions in connection with this Agreement.</p>
  
  <h3>Confidentiality</h3>
  
  <p>All information provided by you to AFEMax and marked as "Confidential" will be recognized as confidential and proprietary to you.  AFEMax shall not divulge such information to any third party without your express written consent and shall take all reasonable steps to ensure that its employees and agents will not divulge such information to any person other than your representatives. AFEMax shall take all reasonable steps to protect all such information in its custody or control to the same extent you protect such proprietary information.</p>
  
  <p>CONTACTING AFEMax™ should you desire to contact AFEMax™, you may do so via Email: <a href="mailto:info@afemax.com">info@afemax.com</a></p>
  `};
};
