var React = require('react');
var Navbar = require('../../infrastructure/components/Navbar');

var Faq = React.createClass({

  handleSearchChange: function(e) {
    var query = this.refs.search.getDOMNode().value;

    if (query === '') {
      $('.faq').show();
      $('.no-results').hide();
      return;
    }

    $('.faq').hide();

    $('.faq').each(function(){
       if($(this).text().toUpperCase().indexOf(query.toUpperCase()) != -1){
         $(this).show();
       }
    });

    if ($('.faq').is(':visible')) {
      $('.no-results').hide();
    } else {
      $('.no-results').show();
    }
  },

  render: function() {
    return (
      <div>
        <Navbar />

        <div className="title-banner">
          <div className="container">
            <h1>FAQ</h1>
          </div>
        </div>

        <div className="container main-container">
          <div className="row">
            <div className="col-xs-12 col-md-offset-9 col-md-3">
              <div className="input-group input-group-sm">
                <div className="input-group-addon"><i className="fa fa-search"></i></div>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search FAQs"
                  ref="search"
                  onChange={this.handleSearchChange} />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="no-results" style={{display: 'none'}}><p>No results found.</p></div>
              <div className="faq">
                <h3>What is AFEMax™?</h3>
                <p>AFEMax™ is an auction service that optimizes the decision making process by providing the most efficient solution for capital projects. The foundation is in the reverse-auction process in which the price is decreased with each successive bid.</p>
              </div>

              <div className="faq">
                <h3>How does the service work?</h3>
                <p>In this auction, buyers post items for which they require services. By posting to the service, provided the reserve price is met for the project, the buyer is committing to proceeding with the work. Provided the minimum technical requirements are met by the bidder (service provider), bidders then compete for the work via the auction, with the lowest-cost provider at auction close being awarded the work.</p>
              </div>

              <div className="faq">
                <h3>What is the value for my company?</h3>
                <p>More work and more revenue. By posting to the service, buyers are committing to the work provided the project reserve pricing is met. This optimizes service company utilization by getting equipment to the field and working where revenue can be generated for all parties (bidders and buyers).</p>
              </div>

              <div className="faq">
                <h3>Who selects the bidders?</h3>
                <p>The Buyer. By completing the application process, bidders (service providers) identify their safety and insurance registrations, category of services provided, and area of delivery. Buyers will select the pre-qualified applicants from this list for their auction.</p>
              </div>

              <div className="faq">
                <h3>Why should I participate?</h3>
                <p>Buyers authorize the work provided the reserves are met. Utilization is optimized. People go to work and equipment gets in the field. It eliminates bid-shopping and "reading-the-mail" to award "buddies" the work. Transparency is a cornerstone of the service.</p>
              </div>

              <div className="faq">
                <h3>What are the fees?</h3>
                <p>Bidders - don’t buy lunch or coffee every day – buy a subscription! A single business lunch can run over $200, and a coffee meeting over $15. AFEMax™ seller accounts have a signup fee of $108.15 and a monthly service charge of $11.55. AFEMax™ buyers pay an auction fee per auction.</p>
              </div>

              <div className="faq">
                <h3>How do I learn more about the auction items?</h3>
                <p>The Technical Description and Terms links on each auction contain all of the details and minimum requirements for each auction. In the event you have technical questions or clarifications, please email <a href="mailto:engineering@afemax.com">engineering@afemax.com</a> and one of our engineering support staff will be glad to assist you.</p>
              </div>

              <div className="faq">
                <h3>Is my company name made available to other bidders?</h3>
                <p>No. Integrity is a fundamental tenet of AFEMax™. We will never disclose the bid-list to competing suppliers. The buyer and auctioneer are the only users with access to that information.</p>
              </div>

              <div className="faq">
                <h3>I forgot my AFEMax™ Password, or reset my password?</h3>
                <p>Go to the customer login section of the <a href="https://afemax.com/login">AFEMax™ website</a>. Below the login section is the password reset link. Enter your email address and an updated password will be sent to your account.</p>
              </div>

              <div className="faq">
                <h3>I want to retract my bid. How do I do that?</h3>
                <p>Bids cannot be retracted. Bid with the knowledge that each bid is binding and could be a winning bid. In the event of a technical error, please email <a href="mailto:support@afemax.com">support@afemax.com</a>. Please reference the auction number and your userID in your email to expedite support. Note that all open bids at the time of auction close are deemed binding and final.</p>
              </div>

              <div className="faq">
                <h3>How do I contact AFEMax™ customer service?</h3>
                <p>Please email <a href="mailto:support@afemax.com">support@afemax.com</a>. Please reference the auction number and your user ID.</p>
              </div>

              <div className="faq">
                <h3>Is my information secure?</h3>
                <p>Yes. Our services use the same encryption and data protection measures as standard internet protocol to ensure your data remains with you and only within your activity on the site.</p>
              </div>

              <div className="faq">
                <h3>Why can't I type in my bid price?</h3>
                <p>Depending on your keyboard configuration, the number keys at the top of the keyboard are required for data entry. Entries using the number pad may not work from all brands of keyboards. This is a security feature to prevent bidders from entering negative bids!</p>
              </div>

              <div className="faq">
                <h3>What's the difference between a Closed Bid and a Reverse Auction?</h3>
                <p>A closed bid auction works more like a traditional bid process. You may submit supporting documents along with your bid, and the Buyer will select a provider based on a number of factors – not just price. A reverse auction awards the work to the lowest bidder who was invited to the auction. It is 100% the Buyer's decision who gets invited to an auction.</p>
              </div>

              <div className="faq">
                <h3>How do Reserve Prices Work?</h3>
                <p>A reserve price is the maximum amount a buyer is willing to pay for a particular service. If the reserve price is not met in an auction, the buyer may choose to award the work or cancel the auction outright.</p>
              </div>

              <div className="faq">
                <h3>What is the Auto-Bid function?</h3>
                <p>The auto bid function allows a Seller to enter in the minimum bid they are willing to sell at, and the system will automatically outbid any competing bids that are above your minimum bid.</p>
              </div>

              <div className="faq">
                <h3>How does the Minimum bid work?</h3>
                <p>You may enter the lowest price you are willing to accept the work for. The system will automatically bid you one decrement below any bids entered until your minimum is reached. The system will only bid as low as required to win the auction or meet the required reserve.</p>
              </div>

              <div className="faq">
                <h3>Can anyone else see my minimum bid?</h3>
                <p>No, only you can see the minimum bid you've entered in the auto bid function.</p>
              </div>

              <div className="faq">
                <h3>What if two Sellers enter the same Minimum Bid?</h3>
                <p>The first person who entered the minimum bid will own that bid. A second Seller attempting to enter that minimum bid will be automatically notified that they have been outbid, even if the current price is the same as their minimum bid.</p>
              </div>
            </div>
          </div>
        </div>

      </div>
    );
  }
});

module.exports = Faq;
