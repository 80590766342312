var React = require('react');
var canada = require('../../../../js/data/canada');
var usa = require('../../../../js/data/usa');

var StateProvSelect = React.createClass({

  getInitialState: function() {
    return {
      value: this.props.defaultValue
    };
  },

  handleChange: function(e) {
    var value = this.refs.stateProvSelect.getDOMNode().value;

    this.setState({
      value: value
    });

    if (this.props.onChange) {
      this.props.onChange(value);
    }
  },

  render: function() {
    var canadaOptions = [];
    var usaOptions = [];

    canada.forEach(function(option, i) {
      canadaOptions.push(<option value={option.value} key={'canada.' + i}>{option.label}</option>);
    });

    usa.forEach(function(option, i) {
      usaOptions.push(<option value={option.value} key={'usa.' + i}>{option.label}</option>);
    });

    return (
      <select
        ref="stateProvSelect"
        className="form-control"
        defaultValue={this.props.defaultValue}
        onChange={this.handleChange}>
        <option value="">-- Please select --</option>
        <optgroup label="Canada">
          {canadaOptions}
        </optgroup>
        <optgroup label="United States">
          {usaOptions}
        </optgroup>
      </select>);
  }
});

module.exports = StateProvSelect;
