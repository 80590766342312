var React = require('react');
var Navbar = require('../../infrastructure/components/Navbar');
var termsAndConditions = require('../../data/legal/terms_and_conditions');

var TermsAndConditions = React.createClass({
  render: function() {
    return (
      <div>
        <Navbar />

        <div className="title-banner">
          <div className="container">
            <h1>Terms &amp; Conditions</h1>
          </div>
        </div>

        <div className="container main-container">
          <div className="row">
            <div className="col-md-12">
              <div dangerouslySetInnerHTML={termsAndConditions()} />
            </div>
          </div>
        </div>

      </div>
    );
  }
});

module.exports = TermsAndConditions;
