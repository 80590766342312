var Reflux = require('reflux');
var AccountAPIUtils = require('../utils/AccountAPIUtils');

var AccountActions = Reflux.createActions({
  'verifyPasswordResetToken': {asyncResult: true},
  'setupAccount': {asyncResult: true},
  'setPassword': {asyncResult: true},
  'sendPasswordReset': {asyncResult: true},
  'login': {asyncResult: true}
});

AccountActions.verifyPasswordResetToken.listenAndPromise(AccountAPIUtils.verifyPasswordResetToken);
AccountActions.setupAccount.listenAndPromise(AccountAPIUtils.setupAccount);
AccountActions.setPassword.listenAndPromise(AccountAPIUtils.setPassword);
AccountActions.sendPasswordReset.listenAndPromise(AccountAPIUtils.sendPasswordReset);
AccountActions.login.listenAndPromise(AccountAPIUtils.login);

module.exports = AccountActions;
