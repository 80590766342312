var axios = require('axios');
var config = require('../../config.js');

var AccountAPIUtils = {

  verifyPasswordResetToken: function(passwordResetToken) {
    return axios.post(config.apiRoot + '/account/verify_password_reset_token', {
      passwordResetToken: passwordResetToken
    });
  },

  setupAccount: function(passwordResetToken, newPassword, timezone) {
    return axios.post(config.apiRoot + '/account/setup_account', {
      passwordResetToken: passwordResetToken,
      newPassword: newPassword,
      timezone: timezone
    });
  },

  setPassword: function(passwordResetToken, newPassword) {
    return axios.post(config.apiRoot + '/account/token_set_password', {
      passwordResetToken: passwordResetToken,
      newPassword: newPassword
    });
  },

  sendPasswordReset: function(email) {
    return axios.post(config.apiRoot + '/account/send_password_reset', {
      email: email
    });
  },

  login: function(email, password) {
    return axios.post(config.apiRoot + '/account/login', {
      email: email,
      password: password
    });
  }

};

module.exports = AccountAPIUtils;
