module.exports = function() {
  return {
    __html: `
<p>AFEMax™ has created this privacy statement to demonstrate its commitment to user privacy. The following discloses our information gathering, use and dissemination practices for the Web Site located at the URL www.afemax.com (the "Web Site"). AFEMax™ reserves the right to change this policy at any time by posting a new privacy policy at this location.</p>

<h3>What information does AFEMax™ gather and how do you use it?</h3>
<p>Information such as your name, address, email address, and other personal information may be collected when you complete contact forms, demonstration request forms, employment application forms and other forms at our Web Site, when you use certain services and when you enter promotions or contests. We collect an IP address from all visitors to our Web Site to help us diagnose problems and administer the Web Site. Demographic and profile data (age, zip code, etc.) may be collected at our Web Site. If you choose not to provide certain required information, you may not be able to use the Web Site. We may use your personal information to contact and correspond with you, respond to your communications, process transactions, charge for services, facilitate and enhance your use of the Web Site, to provide you with information and services requested through the Web Site notify you of any material changes in our Web Site's policies and as otherwise permitted by law. </p>
<p>We will not share your contact information with any third party without your consent, except to a court or governmental agency if required by law.</p>
<p>We will not share your financial, demographic, or profile information with any third party without your consent, except to a court or governmental agency if required by law, with the exception that we may use such information in "blinded" form, stripped of reference to particular individuals, for research or other purposes. We may also maintain a database for use in aggregate form for various tracking and reporting reasons.
</p>
<p>AFEMax™ may copy Web Site configuration files or "cookies" to your computer to enable you to use our services more easily and to let us keep track of certain statistical information. If you chose to have your browser software refuse cookies, it is possible that some areas of the Web Site will not function properly when you attempt to view them. </p>

<h3>How secure is my information?</h3>
<p>The AFEMax™ Web Site has security measures in place to protect against the loss, misuse, and alteration of the information under our control. Unfortunately, no data transmission over the Internet can be guaranteed to be 100% secure. As a result, while we strive to protect your personal information, AFEMax™ cannot ensure or warrant the security of any information you transmit to us or from our on-line services, and you do so at your own risk.</p>

<h3>Will I receive communications from AFEMax™?</h3>
<p>If you contact AFEMax™ or otherwise indicate that you would like to receive email from AFEMax™, then AFEMax™ may send to you, from time to time, email or other communications containing information about AFEMax™ and other matters (including third party products or services) it believes will interest you.</p>

<h3>What happens to my personal information, when I stop using AFEMax™ Web Site?</h3>
<p>If you stop using the Web Site or your permission to use the Web Site is terminated, we may continue to use and disclose your personal information in accordance with this Policy as amended from time to time.</p>

<h3>Does AFEMax™ have an opt-out policy?</h3>
<p>If, at any time, you change your mind about receiving information from AFEMax™ or about sharing information with other parties, please send us a request specifying your new choice in an email sent to <a href="mailto:optout@afemax.com">optout@afemax.com</a> or by mail to the address listed below. In addition, each email we send you will tell you how to decline further email.</p>

<h3>How can I contact AFEMax™?</h3>
<p>If you encounter any Web Site related problem, send an email to <a href="mailto:support@afemax.com">support@afemax.com</a>
For general company or Web site information, you can send an email to us with any further questions to <a href="mailto:info@afemax.com">info@afemax.com</a></p>
<p>If you have any questions about this privacy statement, the practices of this Web Site, or your dealings with this Web Site, you can contact <a href="mailto:info@afemax.com">info@afemax.com</a></p>
  `};
};
