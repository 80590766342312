var Reflux = require('reflux');
var ServiceAPIUtils = require('../utils/ServiceAPIUtils');

var ServiceActions = Reflux.createActions({
  'getServices': {asyncResult: true},
  'createService': {asyncResult: true},
  'getService': {asyncResult: true},
  'updateService': {asyncResult: true},
  'deleteService': {asyncResult: true},
  'getServiceCheckboxes': {asyncResult: true}
});

ServiceActions.getServices.listenAndPromise(ServiceAPIUtils.getServices);
ServiceActions.createService.listenAndPromise(ServiceAPIUtils.createService);
ServiceActions.getService.listenAndPromise(ServiceAPIUtils.getService);
ServiceActions.updateService.listenAndPromise(ServiceAPIUtils.updateService);
ServiceActions.deleteService.listenAndPromise(ServiceAPIUtils.deleteService);
ServiceActions.getServiceCheckboxes.listenAndPromise(ServiceAPIUtils.getServiceCheckboxes);

module.exports = ServiceActions;
