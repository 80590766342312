var React = require('react/addons');

var ServiceCheckboxes = React.createClass({

  getDefaultProps: function() {
    return {
      checked: [],
      serviceCheckboxes: []
    };
  },

  componentDidMount: function() {
    // Initialize services checkbox handlers
    $('input[name="services[]"]').off();
    $('.subSubService').off();

    $('input[name="services[]"][data-checkall="true"]').on('change', function(e) {
      var checked = $(this).prop('checked');
      var id = $(this).val();
      $('input[name="services[]"][data-parent="'+id+'"]').prop('checked', checked);
    });

    // Check checkall if all checked, vise-vera
    $('.subSubService').on('change', function(e) {
      var parent = $(this).data('parent');
      var allChecked = true;
      $('input[name="services[]"][data-parent="'+parent+'"]').each(function(e) {
        var checked = $(this).prop('checked');
        if (! checked) {
          allChecked = false;
        }
      });
      $('input[name="services[]"][value="'+parent+'"]').prop('checked', allChecked);
    });
  },

  componentWillUnmount: function() {
    $('input[name="services[]"]').off();
    $('.subSubService').off();
  },

  getFormData: function() {
    var services = [];

    $('input[name="services[]"]').each(function() {
      if ($(this).prop('checked')) {
        services.push($(this).val());
      }
    });

    return services;
  },

  toggleServiceSection: function(e) {
    var serviceSection = $(e.target).data('servicesection');

    $('.serviceSection[data-servicesection="' + serviceSection + '"]').slideToggle();
  },

  render: function() {
    var services = [];

    if (this.props.serviceCheckboxes) {
      this.props.serviceCheckboxes.forEach(function(service, i) {
        var serviceSection = [];

        service.services.forEach(function(subService, i) {
          var serviceSectionServices = [];

          subService.services.forEach(function(subSubService, i) {

            var checkbox;

            if ($.inArray(subSubService.id, this.props.checked) !== -1) {
              checkbox = (<input type="checkbox" className="subSubService" name="services[]" data-parent={subService.id} value={subSubService.id} defaultChecked/>);
            } else {
              checkbox = (<input type="checkbox" className="subSubService" name="services[]" data-parent={subService.id} value={subSubService.id} />);
            }

            serviceSectionServices.push(<div className="col-sm-4" key={'service.' + subSubService.id}>
              <div className="form-group" style={{marginBottom: '0px'}}>
                <div className="checkbox" style={{marginBottom: '0px'}}>
                  <label>
                    {checkbox}{subSubService.name}
                  </label>
                </div>
              </div>
            </div>);
          }, this);

          var checkAll = subService.services.length ? ' (check all)' : '';

          var checkbox;

          if ($.inArray(subService.id, this.props.checked) !== -1) {
            checkbox = (<input type="checkbox" name="services[]" value={subService.id} data-checkall={subService.services.length ? true : false} defaultChecked/>);
          } else {
            checkbox = (<input type="checkbox" name="services[]" value={subService.id} data-checkall={subService.services.length ? true : false} />);
          }

          serviceSection.push(
            <div className="serviceSection clearfix" data-servicesection={service.id} key={'service.' + subService.id} style={{marginTop: '20px', marginLeft: '10px'}}>
              <div className="col-sm-12">
                <div className="form-group" style={{marginBottom: '0px'}}>
                  <div className="checkbox" style={{marginBottom: '0px'}}>
                    <label>
                      {checkbox}<strong>{subService.name}</strong> {checkAll}
                    </label>
                  </div>
                </div>
              </div>
              {serviceSectionServices}
            </div>
          );

        }, this);

        services.push(<div className="row clearfix" key={'serviceSection' + service.id} style={{marginBottom: '20px'}}>
          <div className="col-sm-12" style={{marginBottom: '10px'}}>
            <button type="button" className="btn" onClick={this.toggleServiceSection} data-servicesection={service.id}>{service.name}</button>
          </div>
          {serviceSection}
        </div>);

      }, this);
    }

    return (<div>{services}</div>);
  }

});

module.exports = ServiceCheckboxes;
