var axios = require('axios');
var config = require('../../config.js');

var SignupAPIUtils = {

  requestCode: function(name, organizationName, organizationType, mobileNumber) {
    return axios.post(config.apiRoot + '/registration/request_code', {
      name: name,
      organizationName: organizationName,
      organizationType: organizationType,
      mobileNumber: mobileNumber
    });
  },

  verifyCode: function(mobileNumber, verificationCode) {
    return axios.post(config.apiRoot + '/registration/verify_code', {
      mobileNumber: mobileNumber,
      verificationCode: verificationCode
    });
  },

  verifyAccessToken: function(accessToken) {
    return axios.post(config.apiRoot + '/registration/verify_access_token', {
      accessToken: accessToken
    });
  },

  acceptPrivacyPolicy: function(accessToken) {
    return axios.post(config.apiRoot + '/registration/accept_privacy_policy', {
      accessToken: accessToken
    });
  },

  acceptTermsAndConditions: function(accessToken) {
    return axios.post(config.apiRoot + '/registration/accept_terms_and_conditions', {
      accessToken: accessToken
    });
  },

  registerSeller: function(sellerRegistrationData) {
    return axios.post(config.apiRoot + '/registration/register_seller', {
      sellerRegistrationData: sellerRegistrationData
    });
  },

  registerBuyer: function(buyerRegistrationData) {
    return axios.post(config.apiRoot + '/registration/register_buyer', {
      buyerRegistrationData: buyerRegistrationData
    });
  },

  registerConsultant: function(consultantRegistrationData) {
    return axios.post(config.apiRoot + '/registration/register_consultant', {
      consultantRegistrationData: consultantRegistrationData
    });
  }

};

module.exports = SignupAPIUtils;
