var Reflux = require('reflux');
var AccountActions = require('../actions/AccountActions');

var errors = {};

var AccountSetupStore = Reflux.createStore({

  listenables: [AccountActions],

  getErrors: function() {
    return errors;
  },

  onSetupAccountCompleted: function (res) {
    errors = {};
    this.trigger('SETUP_ACCOUNT_COMPLETED');
  },

  onSetupAccountFailed: function (res) {
    if (typeof res.data.errors !== 'undefined') {
      errors = res.data.errors;
    }

    this.trigger('SETUP_ACCOUNT_FAILED');
  }

});

module.exports = AccountSetupStore;
