var Reflux = require('reflux');
var StoreErrorMixin = require('../mixins/StoreErrorMixin');
var ServiceActions = require('../actions/ServiceActions');

var serviceAreas = [];
var serviceArea = null;
var serviceCheckboxes = [];

var ServiceStore = Reflux.createStore({

  listenables: [ServiceActions],

  mixins: [StoreErrorMixin],

  getServices: function() {
    return serviceAreas;
  },

  getService: function() {
    return serviceArea;
  },

  // Collection
  onGetServicesCompleted: function(res) {
    this.clearErrors();
    serviceAreas = res.data;
    this.trigger('GET_SERVICES_COMPLETED');
  },

  onGetServicesFailed: function(res) {
    this.setErrors(res);
    this.trigger('GET_SERVICES_FAILED');
  },

  // Model
  onCreateServiceCompleted: function(res) {
    this.clearErrors();
    this.trigger('CREATE_SERVICE_COMPLETED');
  },

  onCreateServiceFailed: function(res) {
    this.setErrors(res);
    this.trigger('CREATE_SERVICE_FAILED');
  },

  onGetServiceCompleted: function(res) {
    this.clearErrors();
    serviceArea = res.data;
    this.trigger('GET_SERVICE_COMPLETED');
  },

  onGetServiceFailed: function(res) {
    this.setErrors(res);
    this.trigger('GET_SERVICE_FAILED');
  },

  onUpdateServiceCompleted: function(res) {
    this.clearErrors();
    this.trigger('UPDATE_SERVICE_COMPLETED');
  },

  onUpdateServiceFailed: function(res) {
    this.setErrors(res);
    this.trigger('UPDATE_SERVICE_FAILED');
  },

  onDeleteServiceCompleted: function(res) {
    this.clearErrors();
    this.trigger('DELETE_SERVICE_COMPLETED');
  },

  onDeleteServiceFailed: function(res) {
    this.setErrors(res);
    this.trigger('DELETE_SERVICE_FAILED');
  },

  // Checkboxes
  getServiceCheckboxes: function() {
    return serviceCheckboxes;
  },

  onGetServiceCheckboxesCompleted: function(res) {
    this.clearErrors();
    serviceCheckboxes = res.data.services;
    this.trigger('GET_SERVICE_CHECKBOXES_COMPLETED');
  },

  onGetServiceCheckboxesFailed: function(res) {
    this.setErrors(res);
    this.trigger('GET_SERVICE_CHECKBOXES_FAILED');
  }

});

module.exports = ServiceStore;
