var Reflux = require('reflux');
var SignupActions = require('../actions/SignupActions');

var organizationType = null;

var AcceptTermsAndConditionsStore = Reflux.createStore({

  listenables: [SignupActions],

  getOrganizationType: function() {
    return organizationType;
  },

  onAcceptTermsAndConditionsCompleted: function (res) {
    organizationType = res.data.organizationType;
    this.trigger('ACCEPT_TERMS_AND_CONDITIONS_COMPLETED');
  },

  onAcceptTermsAndConditionsFailed: function (res) {
    this.trigger('ACCEPT_TERMS_AND_CONDITIONS_FAILED');
  }

});

module.exports = AcceptTermsAndConditionsStore;
