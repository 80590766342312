var React = require('react/addons');

var WorkExperience = React.createClass({

  getInitialState: function() {
    return {
      numberOfFieldGroups: 1
    }
  },

  getDefaultProps: function() {
    return {
      identifier: ''
    };
  },

  getFormData: function() {
    var data = [];

    $('div[data-identifier="' + this.props.identifier + 'WorkExperience"]').each(function() {
      if ($(this).find('input[name="company_name"]').val()) {
        data.push({
          company_name: $(this).find('input[name="company_name"]').val(),
          position: $(this).find('input[name="position"]').val(),
          start_year: $(this).find('select[name="start_year"]').val(),
          end_year: $(this).find('select[name="end_year"]').val(),
          responsibilities: $(this).find('textarea[name="responsibilities"]').val()
        });
      }
    });

    return data;
  },

  handleAddFieldGroup: function() {
    var numberOfFieldGroups = this.state.numberOfFieldGroups;
    numberOfFieldGroups++;
    this.setState({numberOfFieldGroups: numberOfFieldGroups});
  },

  render: function() {
    // Year options
    var yearOptions = [];
    var thisYear = new Date().getFullYear();

    for (var y = thisYear; y >= 1950; y--) {
      yearOptions.push(<option value={y} key={'year.' + y}>{y}</option>);
    }

    var workExperience = [];

    for (var i = 0; i < this.state.numberOfFieldGroups; i++) {
      workExperience.push(
        <div
          className="card"
          style={{borderTop: '1px solid #E8E8E8', marginTop: '10px'}}
          key={this.props.identifier + 'WorkExperience.' + i}
          data-identifier={this.props.identifier + 'WorkExperience'}>
          <div className="row">
            <div className="col-sm-4">
              <div className="form-group">
                <label className="control-label">Company name</label>
                <input type="text" className="form-control" placeholder="Company name" name="company_name" />
              </div>
            </div>
            <div className="col-sm-4">
              <div className="form-group">
                <label className="control-label">Position</label>
                <input type="text" className="form-control" placeholder="Position" name="position" />
              </div>
            </div>
            <div className="col-sm-2">
              <div className="form-group">
                <label className="control-label">From</label>
                <select className="form-control" name="start_year">
                  <option value="">-- Please select --</option>
                  {yearOptions}
                </select>
              </div>
            </div>
            <div className="col-sm-2">
              <div className="form-group">
                <label className="control-label">Until</label>
                <select className="form-control" name="end_year">
                  <option value="">-- Please select --</option>
                  {yearOptions}
                </select>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="form-group">
                <label className="control-label">Responsibilities</label>
                <textarea className="form-control" name="responsibilities"/>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div>
        {workExperience}

        <div className="pull-right">
          <a href="javascript:;" onClick={this.handleAddFieldGroup}> + Add Work Experience</a>
        </div>
      </div>
    );
  }

});

module.exports = WorkExperience;
