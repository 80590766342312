var React = require('react/addons');
var Reflux = require('reflux');
var Router = require('react-router');
var Navbar = require('../../infrastructure/components/Navbar');
var SignupActions = require('../actions/SignupActions');
var VerifyAccessTokenStore = require('../stores/VerifyAccessTokenStore');
var RegisterBuyerStore = require('../stores/RegisterBuyerStore');

var General = require('../../../account/js/components/organizations/form/General');
var Contact = require('../../../account/js/components/shared/Contact');

var ServiceAreaActions = require('../../../account/js/actions/ServiceAreaActions');
var ServiceAreaStore = require('../../../account/js/stores/ServiceAreaStore');
var ServiceAreaCheckboxes = require('../../../account/js/components/shared/ServiceAreaCheckboxes');

var IndustrySelect = require('../../../account/js/components/shared/IndustrySelect');
var BillingOptions = require('../../../account/js/components/organizations/form/BillingOptions');

var BuyerRegistration = React.createClass({

  mixins: [React.addons.LinkedStateMixin, Reflux.ListenerMixin, Router.Navigation],

  getInitialState: function() {
    return {
      alertDanger: null,
      alertSuccess: null,
      hideForm: false,
      validatorOn: false,

      serviceAreaCheckboxes: null,
      serviceAreaProvinceCheckboxes: null
    };
  },

  componentWillMount: function() {
    // Verify access token
    this.listenTo(VerifyAccessTokenStore, this.onVerifyAccessTokenStore);
    SignupActions.verifyAccessToken(this.props.params.accessToken);

    // Get service areas
    this.listenTo(ServiceAreaStore, this.onServiceAreaStore);
    ServiceAreaActions.getServiceAreaCheckboxes();

    // Handle registration
    this.listenTo(RegisterBuyerStore, this.onRegisterBuyerStore);
  },

  componentDidUpdate: function() {
    if (! this.state.validatorOn && this.state.serviceAreaCheckboxes) {
      this.setState({
        validatorOn: true
      });

      $('#registration-form').validator().on('submit', this.handleRegistrationSubmit);
    }
  },

  componentWillUnmount: function() {
    $('#registration-form').validator('destroy').off();
  },

  onVerifyAccessTokenStore: function(status) {
    if (status === 'VERIFY_ACCESS_TOKEN_FAILED') {
      this.setState({
        alertDanger: 'Invalid access token.',
        hideForm: true
      });
    }
  },

  onServiceAreaStore: function(status) {
    if (status === 'GET_SERVICE_AREA_CHECKBOXES_COMPLETED') {
      this.setState({
        serviceAreaCheckboxes: ServiceAreaStore.getServiceAreaCheckboxes(),
        serviceAreaProvinceCheckboxes: ServiceAreaStore.getServiceAreaProvinceCheckboxes()
      });
    } else if (status === 'GET_SERVICE_AREA_CHECKBOXES_FAILED') {
      this.setState({alertDanger: 'Service areas unavailable.'});
    }
  },

  handleRegistrationSubmit: function(e) {
    this.setState({
      alertDanger: null,
      alertSuccess: null
    });

    if (! e.isDefaultPrevented()) {
      e.preventDefault();
      $('.btn-submit').prop('disabled', true);

      var alternateContact = null;
      var accountingContact = null;

      if (this.refs.alternateContact.state.first_name) {
        alternateContact = {
          first_name: this.refs.alternateContact.state.first_name,
          last_name: this.refs.alternateContact.state.last_name,
          phone: this.refs.alternateContact.state.phone,
          email: this.refs.alternateContact.state.email
        };
      }

      if (this.refs.accountingContact.state.first_name) {
        accountingContact = {
          first_name: this.refs.accountingContact.state.first_name,
          last_name: this.refs.accountingContact.state.last_name,
          phone: this.refs.accountingContact.state.phone,
          email: this.refs.accountingContact.state.email
        };
      }

      var organizationData = {
        reg_access_token: this.props.params.accessToken,

        name: this.refs.general.state.name,
        address: this.refs.general.state.address,
        city: this.refs.general.state.city,
        state: this.refs.general.state.state,
        country: this.refs.general.state.country,
        postal_zip: this.refs.general.state.postal_zip,
        phone: this.refs.general.state.phone,
        fax: this.refs.general.state.fax,
        email: this.refs.general.state.email,

        primaryContact: {
          first_name: this.refs.primaryContact.state.first_name,
          last_name: this.refs.primaryContact.state.last_name,
          phone: this.refs.primaryContact.state.phone,
          email: this.refs.primaryContact.state.email
        },

        alternateContact: alternateContact,
        accountingContact: accountingContact,

        primary_industry: this.refs.primaryIndustry.state.industry,
        billing_preference: this.refs.billingPreference.state.preference,

        serviceAreas: this.refs.serviceAreas.getFormData()
      };

      SignupActions.registerBuyer(organizationData);
    } else {
      e.preventDefault();
      $('.btn-submit').prop('disabled', false);
      this.setState({alertDanger: 'Please ensure all required fields are filled in.'});
      window.scrollTo(0,0);
    }
  },

  onRegisterBuyerStore: function(status) {
    if (status === 'REGISTER_BUYER_COMPLETED') {
      this.transitionTo('buyer-registration-complete');
    } else if (status === 'REGISTER_BUYER_FAILED') {
      $('.btn-submit').prop('disabled', false);
      window.scrollTo(0,0);

      var errors = RegisterBuyerStore.getErrors();

      if (typeof errors.registration !== 'undefined') {
        this.setState({alertDanger: errors.registration});
      } else {
        this.setState({alertDanger: 'Registration could not be processed. Please contact us for assistance.'});
      }

    }
  },

  render: function() {
    var alertDanger;
    var alertSuccess;
    var paymentErrorMessage;
    var form;

    alertDanger = this.state.alertDanger ? (
      <div className="alert alert-danger" role="alert">
        <i className="fa fa-exclamation-circle"></i> <strong>Error!</strong> {this.state.alertDanger}
      </div>
    ) : '';

    alertSuccess = this.state.alertSuccess ? (
      <div className="alert alert-success" role="alert">
        <i className="fa fa-check-circle"></i> {this.state.alertSuccess}
      </div>
    ) : '';

    if (this.state.serviceAreaCheckboxes && ! this.state.hideForm) {
      form = (
        <form id="registration-form" className="card">

          <h3>1.0 General</h3>

          <General
            ref="general"/>

          <h3>2.0 Primary Contact</h3>

          <div className="row">
            <div className="col-md-12">
              <div className="alert alert-info" role="alert">
                <strong>Note:</strong> An account will be created based off of your primary contact.
              </div>
            </div>
          </div>

          <Contact ref="primaryContact" required={['first_name', 'last_name', 'phone', 'email']} />

          <h3>3.0 Alternate Contact</h3>

          <Contact ref="alternateContact" />

          <h3>4.0 Accounting Contact</h3>

          <Contact ref="accountingContact" required={['first_name', 'last_name', 'phone', 'email']} />

          <h3>5.0 Billing Preference</h3>

          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <label className="control-label">Billing Preference *</label>
                <BillingOptions ref="billingPreference" />
                <div className="help-block with-errors"></div>
              </div>
            </div>
          </div>

          <h3>6.0 Primary Industry</h3>

          <div className="row">
            <div className="col-md-4">
              <div className="form-group">
                <label htmlFor="selectPrimaryIndustry" className="control-label">Primary Industry *</label>
                <IndustrySelect id="selectPrimaryIndustry" ref="primaryIndustry" />
                <div className="help-block with-errors"></div>
              </div>
            </div>
          </div>

          <h3>7.0 Service Areas</h3>

          <ServiceAreaCheckboxes
            ref="serviceAreas"
            serviceAreaCheckboxes={this.state.serviceAreaCheckboxes}
            serviceAreaProvinceCheckboxes={this.state.serviceAreaProvinceCheckboxes} />

          <div className="row" style={{marginTop: '20px'}}>
            <div className="col-md-12">
              <button className="btn btn-primary btn-submit" type="submit">Submit</button>
            </div>
          </div>
        </form>
      );
    }

    return (
      <div>
        <Navbar />

        <div className="title-banner">
          <div className="container">
            <h1>Sign up</h1>
          </div>
        </div>

        <div className="container main-container">
          <div className="row">
            <div className="col-md-12">
              <h3>Buyer Registration</h3>
              {alertDanger}
              {alertSuccess}
              {form}
            </div>
          </div>
        </div>

      </div>
    );
  }

});

module.exports = BuyerRegistration;
