var React = require('react/addons');
var timezones = require('../../../../js/data/timezones');

var TimezoneSelect = React.createClass({

  mixins: [React.addons.LinkedStateMixin],

  getDefaultProps: function() {
    return {
      id: 'selectTimezone',
      options: [],
      value: null,
      required: false
    };
  },

  getInitialState: function() {
    return {
      options: this.props.options,
      value: this.props.value
    }
  },

  componentDidMount: function() {
    if (this.props.required) {
      $(this.refs.select.getDOMNode()).prop('required', true);
    }
  },

  render: function() {
    var options = [];

    timezones.forEach(function(option, i) {
      options.push(<option value={option.zone} key={'timezone.' + i}>{option.offset} {option.zone}</option>);
    });

    return (
      <div className="form-group">
        <label htmlFor={this.props.id} className="control-label">Timezone {this.props.required ? '*' : ''}</label>
        <select
          ref="select"
          id={this.props.id}
          className="form-control"
          valueLink={this.linkState('value')}>
          <option value="">-- Please select --</option>
          {options}
        </select>
        <div className="help-block with-errors"></div>
      </div>
    );
  }
});

module.exports = TimezoneSelect;
