var React = require('react/addons');

var certifications = [
  'Safety Management and Regulatory Awareness for Wellsite Supervision',
  'Second Line Supervisor\'s Well Control',
  'Well Service Blowout Prevention',
  'Detection and Control of Flammable Substances',
  'WHMIS',
  'Standard First Aid',
  'TDG',
  'H2S Alive'
];

var Certifications = React.createClass({

  getInitialState: function() {
    return {
      numberOfFieldGroups: 1
    }
  },

  getDefaultProps: function() {
    return {
      identifier: ''
    };
  },

  componentDidMount: function() {
    $('.datefield' + this.props.identifier).datepicker({
      autoclose: true,
      orientation: 'top left',
      startDate: new Date()
    }).val('');
  },

  componentDidUpdate: function() {
    // Re-Initialize date pickers
    $('.datefield' + this.props.identifier).datepicker({
      autoclose: true,
      orientation: 'top left',
      startDate: new Date()
    }).val('');
  },

  componentWillUnmount: function() {
    $('.datefield' + this.props.identifier).datepicker('remove');
  },

  getFormData: function() {
    var data = [];

    $('div[data-identifier="' + this.props.identifier + 'Certification"]').each(function() {
      if ($(this).find('select[name="certification_name"]').val()) {
        data.push({
          certification_name: $(this).find('select[name="certification_name"]').val(),
          expires_on: $(this).find('input[name="expires_on"]').val()
        });
      }
    });

    return data;
  },

  handleAddFieldGroup: function() {
    var numberOfFieldGroups = this.state.numberOfFieldGroups;
    numberOfFieldGroups++;
    this.setState({numberOfFieldGroups: numberOfFieldGroups});
  },

  render: function() {
    var certificationOptions = [];

    for (var i = 0; i < certifications.length; i++) {
      certificationOptions.push(<option value={certifications[i]} key={'certification.' + i}>{certifications[i]}</option>);
    }

    var workExperience = [];

    for (var i = 0; i < this.state.numberOfFieldGroups; i++) {
      var expiryClasses = 'form-control datefield' + this.props.identifier;

      workExperience.push(
        <div
          className="card"
          style={{borderTop: '1px solid #E8E8E8', marginTop: '10px'}}
          key={this.props.identifier + 'Certification.' + i}
          data-identifier={this.props.identifier + 'Certification'}>
          <div className="row">
            <div className="col-sm-6">
              <div className="form-group">
                <label className="control-label">Certification Name</label>
                <select className="form-control" name="certification_name">
                  <option value="">-- Please select --</option>
                  {certificationOptions}
                </select>
              </div>
            </div>
            <div className="col-sm-2">
              <div className="form-group">
                <label className="control-label">Expiry</label>
                <input type="text"
                  className={expiryClasses}
                  name="expires_on"/>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div>
        {workExperience}

        <div className="pull-right">
          <a href="javascript:;" onClick={this.handleAddFieldGroup}> + Add Certification</a>
        </div>
      </div>
    );
  }

});

module.exports = Certifications;
