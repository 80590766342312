var React = require('react/addons');

var SafetyProgram = React.createClass({
  mixins: [React.addons.LinkedStateMixin],

  getDefaultProps: function() {
    return {
      organization: {
        sa_written_safety_program: false,
        sa_conduct_hazard_assessments: false,
        sa_safe_work_procedures: false,
        sa_one_man_op: false,
        sa_third_party_sub: false
      }
    };
  },

  getInitialState: function() {
    return {
      sa_written_safety_program: this.props.organization.sa_written_safety_program,
      sa_conduct_hazard_assessments: this.props.organization.sa_conduct_hazard_assessments,
      sa_safe_work_procedures: this.props.organization.sa_safe_work_procedures,
      sa_one_man_op: this.props.organization.sa_one_man_op,
      sa_third_party_sub: this.props.organization.sa_third_party_sub
    };
  },

  render: function() {
    return (
      <div>
        <div className="row">
          <div className="col-sm-12">
            <div className="form-group">
              <div className="checkbox">
                <label>
                  <input type="checkbox" checkedLink={this.linkState('sa_written_safety_program')} /> Does your company have a written safety program?
                </label>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-12">
            <div className="form-group">
              <div className="checkbox">
                <label>
                  <input type="checkbox" checkedLink={this.linkState('sa_conduct_hazard_assessments')} /> Does your company have/conduct hazard assessments?
                </label>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-12">
            <div className="form-group">
              <div className="checkbox">
                <label>
                  <input type="checkbox" checkedLink={this.linkState('sa_safe_work_procedures')} /> Does your company have safe work procedures?
                </label>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-12">
            <div className="form-group">
              <div className="checkbox">
                <label>
                  <input type="checkbox" checkedLink={this.linkState('sa_one_man_op')} /> Are you a one man operation?
                </label>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-12">
            <div className="form-group">
              <div className="checkbox">
                <label>
                  <input type="checkbox" checkedLink={this.linkState('sa_third_party_sub')} /> Does your company utilize independent third party sub-contractors or consultants under your company name at worksites?
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
});

module.exports = SafetyProgram;
