var React = require('react');
var Navbar = require('../../infrastructure/components/Navbar');
var Login = require('./Login');

var Index = React.createClass ({
  render: function() {
    var sampleAuctionImageStyle = {
      display: 'inline-block'
    };

    return (
      <div>
        <Navbar />

        <div className="title-banner">
          <div className="container">
            <h1>Welcome to AFEMax™</h1>
          </div>
        </div>

        <div className="container main-container">
          <Login showLogo={false} />
        </div>

      </div>
    );
  }
});

module.exports = Index;
