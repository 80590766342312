var React = require('react');
var Navbar = require('../../infrastructure/components/Navbar');

var BuyerRegistrationComplete = React.createClass({

  render: function() {
    return (
      <div>
        <Navbar />

        <div className="title-banner">
          <div className="container">
            <h1>Thank you</h1>
          </div>
        </div>

        <div className="container main-container">
          <div className="row">
            <div className="col-md-12">
              <h3>Registration Complete</h3>
              <p>Your registration has been submitted. Once your application has been reviewed you will be notified by email.</p>
            </div>
          </div>
        </div>

      </div>
    );
  }

});

module.exports = BuyerRegistrationComplete;
