var React = require('react');
var Navbar = require('../../infrastructure/components/Navbar');

var About = React.createClass({
  render: function() {
    var sampleAuctionImageStyle = {
      display: 'inline-block',
      maxWidth: '650px',
      margin: '20px 0'
    };
    
    return (
      <div>
        <Navbar />

        <div className="title-banner">
          <div className="container">
            <h1>About</h1>
          </div>
        </div>

        <div className="container main-container">
          <div className="row">
            <div className="col-md-12">
              <h3>AFEMax™</h3>
              <p>AFEMax™ is an exclusive upstream oil and gas focused auction service aimed at reducing project costs to "Buyers" and increasing utilization for "Sellers". The concept behind AFEMax™ is to achieve the maximum unit cost reductions through public reverse-bidding auctions. The auction is a process in which Sellers bid for the prices at which they are willing to provide their goods and services to the Buyer’s project. The role of the AFEMax Consultant is to connect the Buyer’s requirements with the Sellers capacity through AFEMax and generating the greatest value to both parties.</p>
              
              <h3>Cost reduction</h3>
              <p>AFEMax™ is proven to significantly reduce the cost of goods and services by having Sellers competitively bid on the work. With pricing information disclosed to all participants during an auction, repeat bids are encouraged, thereby lowering the cost of services.</p>
              
              <h3>Value Retention</h3>
              <p>High quality results are achieved through:</p>
              
              <ul>
                <li>Clear Assessment of Project Performance Targets as compared to offsets</li>
                <li>Project specific job specifications</li>
                <li>Preselection of qualified Sellers</li>
                <li>Award to most cost effective Sellers that meet specifications</li>
                <li>Contractor safety, insurance and WCB visibility and filtering</li>
              </ul>
              
              <h3>Time sensitive</h3>

              <p>As AFEMax is a web based application, it can be accessed by Buyers and Sellers at any time and from anywhere with an Internet connection. A finite timeline is set for every auction. Sellers will now have a reasonable, but limited time, to submit proposals, depending on Buyer preferences. This significantly reduces the tendering timeline and increases the accuracy of results compared to conventional bidding scenarios related to the sealed bid process, normalizations, and clarifications.</p>

              <div className="text-center">
                <img alt="Sample of the auction process" src="/img/auction-process.png" className="img-responsive" style={sampleAuctionImageStyle} />
              </div>
              
              <p>To maximize the Buyer’s project value, Sellers are prescreened by the AFEMax Consultant and/or the Buyer to assure they at least meet the Buyers purchasing requirements (e.g. SECOR, COR, COMPLYWORKS, etc.). Further, Sellers are screened on core competencies of safety, efficiency and accuracy.</p>
              
              <p>A minimum technical standard (e.g. Drilling or Completions program) may be posted for Sellers to evaluate prior to making a submission. Incomplete bids or sub-standard bids are rejected.</p>
              
              <p>Bids for services are then technically evaluated by the AFEMax Consultant, or with the Buyer, to assure they pass the minimum engineering requirements for the project.</p>
              
            </div>
          </div>
        </div>

      </div>
    );
  }
});

module.exports = About;
