var Reflux = require('reflux');
var AccountActions = require('../actions/AccountActions');

var VerifyPasswordResetTokenStore = Reflux.createStore({

  listenables: [AccountActions],

  onVerifyPasswordResetTokenCompleted: function (res) {
    this.trigger('VERIFY_PASSWORD_RESET_TOKEN_COMPLETED');
  },

  onVerifyPasswordResetTokenFailed: function (res) {
    this.trigger('VERIFY_PASSWORD_RESET_TOKEN_FAILED');
  }

});

module.exports = VerifyPasswordResetTokenStore;
