var React = require('react/addons');
var Reflux = require('reflux');
var Router = require('react-router');
var Waypoint = require('react-waypoint');
var Navbar = require('../../infrastructure/components/Navbar');
var SignupActions = require('../actions/SignupActions');
var VerifyAccessTokenStore = require('../stores/VerifyAccessTokenStore');
var AcceptPrivacyPolicyStore = require('../stores/AcceptPrivacyPolicyStore');
var privacyPolicy = require('../../data/legal/privacy_policy');

var RegistrationRequest = React.createClass({

  mixins: [React.addons.LinkedStateMixin, Reflux.ListenerMixin, Router.Navigation],

  getInitialState: function() {
    return {
      alert: ''
    };
  },

  componentDidMount: function() {
    // Verify access token
    this.listenTo(VerifyAccessTokenStore, this.onVerifyAccessTokenStore);
    SignupActions.verifyAccessToken(this.props.params.accessToken);

    this.listenTo(AcceptPrivacyPolicyStore, this.onAcceptPrivacyPolicyStore);
  },

  onVerifyAccessTokenStore: function(status) {
    if (status === 'VERIFY_ACCESS_TOKEN_FAILED') {
      this.setState({alert: 'Invalid access token.'});
    }
  },

  onAcceptPrivacyPolicyStore: function(status) {
    if (status === 'ACCEPT_PRIVACY_POLICY_COMPLETED') {
      this.transitionTo('accept-terms-and-conditions', {accessToken: this.props.params.accessToken});
    } else if (status === 'ACCEPT_PRIVACY_POLICY_FAILED') {
      this.setState({alert: 'Invalid access token.'});
    }
  },

  handleWaypointEnter: function(e) {
    $('.btn-agree').prop('disabled', false);
  },

  handleAgree: function(e) {
    e.preventDefault();
    SignupActions.acceptPrivacyPolicy(this.props.params.accessToken);
  },

  render: function() {
    var alert;
    var _privacyPolicy;
    var content;

    alert = this.state.alert ? (
      <div className="alert alert-danger" role="alert">
        <i className="fa fa-exclamation-circle"></i> <strong>Error!</strong> {this.state.alert}
      </div>
    ) : '';

    _privacyPolicy = (
      <div>
        <p>To continue, you must agree to the AFEMax™ Privacy Policy.</p>
        <div className="scrollable">
          <h2>AFEMax™ Privacy Policy</h2>
          <div dangerouslySetInnerHTML={privacyPolicy()} />

          <Waypoint
            onEnter={this.handleWaypointEnter}
            threshold={0}
          />
        </div>
        <form onSubmit={this.handleAgree}>
          <button className="btn btn-lg btn-primary btn-agree" type="submit" disabled>Agree</button>
        </form>
      </div>
    );

    content = alert ? alert : _privacyPolicy;

    return (
      <div>
        <Navbar />

        <div className="title-banner">
          <div className="container">
            <h1>Sign up</h1>
          </div>
        </div>

        <div className="container main-container">
          <div className="row">
            <div className="col-md-12">
              <h3>Privacy Policy</h3>
              {content}
            </div>
          </div>
        </div>

      </div>
    );
  }

});

module.exports = RegistrationRequest;
