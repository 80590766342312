var Reflux = require('reflux');
var SignupActions = require('../actions/SignupActions');

var VerifyAccessTokenStore = Reflux.createStore({

  listenables: [SignupActions],

  onVerifyAccessTokenCompleted: function (res) {
    this.trigger('VERIFY_ACCESS_TOKEN_COMPLETED');
  },

  onVerifyAccessTokenFailed: function (res) {
    this.trigger('VERIFY_ACCESS_TOKEN_FAILED');
  }

});

module.exports = VerifyAccessTokenStore;
