var React = require('react/addons');
var Reflux = require('reflux');
var Router = require('react-router');
var Navbar = require('../../infrastructure/components/Navbar');
var SignupActions = require('../actions/SignupActions');
var VerifyAccessStore = require('../stores/VerifyAccessStore');

var VerifyAccessCode = React.createClass({

  mixins: [React.addons.LinkedStateMixin, Reflux.ListenerMixin, Router.Navigation],

  getInitialState: function() {
    return {
      verificationCode: '',
      alert: ''
    };
  },

  componentDidMount: function() {
    this.listenTo(VerifyAccessStore, this.onVerifyAccessStore);

    // Handle form submit
    $('#verify-code-form').validator().on('submit', this.handleVerifyCodeSubmit);
  },

  componentWillUnmount: function() {
    $('#verify-code-form').validator('destroy').off();
  },

  onVerifyAccessStore: function(status) {
    if (status === 'VERIFY_CODE_COMPLETED') {
      this.transitionTo('accept-privacy-policy', {accessToken: VerifyAccessStore.getAccessToken()});
    } else if (status === 'VERIFY_CODE_FAILED') {
      $('#verify-code-form button').prop('disabled', false);
      this.setState({alert: 'Invalid access code. Please check the code and try again.'});
    }
  },

  handleVerifyCodeSubmit: function(e) {
    this.setState({alert: ''});

    if (! e.isDefaultPrevented()) {
      e.preventDefault();
      $('#verify-code-form button').prop('disabled', true);
      SignupActions.verifyCode(this.props.params.mobileNumber, this.state.verificationCode);
    }
  },

  render: function() {
    var alert = this.state.alert ? (
      <div className="alert alert-danger" role="alert">
        <i className="fa fa-exclamation-circle"></i> <strong>Error!</strong> {this.state.alert}
      </div>
    ) : '';

    return (
      <div>
        <Navbar />

        <div className="title-banner">
          <div className="container">
            <h1>Sign up</h1>
          </div>
        </div>

        <div className="container main-container">
          <div className="row">
            <div className="col-md-6">
              <h3>Verify Access Code</h3>
              <p>A verification code was sent to ({this.props.params.mobileNumber}). Please enter the verification code to continue.</p>
            </div>

            <div className="col-md-6">
              <div className="card card-container">
                {alert}
                <form className="form-card" data-toggle="validator" id="verify-code-form">

                  <div className="form-group">
                    <label htmlFor="inputVerificationCode" className="control-label">Verification code</label>
                    <input type="text" id="inputVerificationCode" className="form-control" placeholder="Enter verification code" valueLink={this.linkState('verificationCode')} required autofocus />
                    <div className="help-block with-errors"></div>
                  </div>

                  <button className="btn btn-lg btn-primary btn-block" type="submit">Verify</button>
                </form>
              </div>

            </div>
          </div>
        </div>

      </div>
    );
  }

});

module.exports = VerifyAccessCode;
