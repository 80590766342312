var React = require('react');
var Navbar = require('../../infrastructure/components/Navbar');
var privacyPolicy = require('../../data/legal/privacy_policy');

var PrivacyPolicy = React.createClass({
  render: function() {
    return (
      <div>
        <Navbar />

        <div className="title-banner">
          <div className="container">
            <h1>Privacy Policy</h1>
          </div>
        </div>

        <div className="container main-container">
          <div className="row">
            <div className="col-md-12">
              <div dangerouslySetInnerHTML={privacyPolicy()} />
            </div>
          </div>
        </div>

      </div>
    );
  }
});

module.exports = PrivacyPolicy;
