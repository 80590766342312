var Reflux = require('reflux');
var SignupActions = require('../actions/SignupActions');

var AcceptPrivacyPolicyStore = Reflux.createStore({

  listenables: [SignupActions],

  onAcceptPrivacyPolicyCompleted: function (res) {
    this.trigger('ACCEPT_PRIVACY_POLICY_COMPLETED');
  },

  onAcceptPrivacyPolicyFailed: function (res) {
    this.trigger('ACCEPT_PRIVACY_POLICY_FAILED');
  }

});

module.exports = AcceptPrivacyPolicyStore;
