var React = require('react');
var Router = require('react-router');
var RouteHandler = Router.RouteHandler;
var boostrap = require('bootstrap');

var App = React.createClass({

  getInitialState: function() {
    var currentTime = new Date();
    var year = currentTime.getFullYear();

    return {
      year: year
    };
  },

  render: function() {
    return (
      <div>

        <RouteHandler {...this.props} />

        <footer className="footer">
          <div className="container clearfix">
            <ul className="footer-nav pull-left">
              <li><a href="/about">About</a></li>
              <li><a href="/faq">FAQ</a></li>
              <li><a href="/terms-and-conditions">Terms &amp; Conditions</a></li>
              <li><a href="/privacy-policy">Privacy Policy</a></li>
            </ul>
            <div className="pull-right copyright">
              <p>&copy; {this.state.year} AFEMax™ All rights reserved.</p>
            </div>
          </div>
        </footer>

      </div>
    );
  }

});

module.exports = App;
