var React = require('react/addons');
var CountrySelect = require('../../shared/CountrySelect');
var StateField = require('../../shared/StateField');

var General = React.createClass({

  mixins: [React.addons.LinkedStateMixin],

  getDefaultProps: function() {
    return {
      organization: {
        name: null,
        address: null,
        city: null,
        state: null,
        country: null,
        postal_zip: null,
        phone: null,
        fax: null,
        email: null
      }
    };
  },

  getInitialState: function() {
    return {
      name: this.props.organization.name,
      address: this.props.organization.address,
      city: this.props.organization.city,
      state: this.props.organization.state,
      country: this.props.organization.country,
      postal_zip: this.props.organization.postal_zip,
      phone: this.props.organization.phone,
      fax: this.props.organization.fax,
      email: this.props.organization.email
    };
  },

  onCountryChange: function(country) {
    this.setState({country: country});
  },

  onStateChange: function(state) {
    this.setState({state: state});
  },

  render: function() {
    return (
      <div>
        <div className="row">
          <div className="col-sm-4">
            <div className="form-group">
              <label htmlFor="inputOrganizationName" className="control-label">Company name *</label>
              <input type="text" id="inputOrganizationName" className="form-control" placeholder="Company name" valueLink={this.linkState('name')} required autofocus />
              <div className="help-block with-errors"></div>
            </div>
          </div>
          <div className="col-sm-2">
            <div className="form-group">
              <label htmlFor="inputPhone" className="control-label">Phone *</label>
              <input type="tel" id="inputPhone" className="form-control" placeholder="Phone" valueLink={this.linkState('phone')} required />
              <div className="help-block with-errors"></div>
            </div>
          </div>
          <div className="col-sm-2">
            <div className="form-group">
              <label htmlFor="inputFax" className="control-label">Fax</label>
              <input type="tel" id="inputOrganizationName" className="form-control" placeholder="Fax" valueLink={this.linkState('fax')}  />
              <div className="help-block with-errors"></div>
            </div>
          </div>
          <div className="col-sm-4">
            <div className="form-group">
              <label htmlFor="inputEmail" className="control-label">Email *</label>
              <input type="email" id="inputEmail" className="form-control" placeholder="Email" valueLink={this.linkState('email')} required />
              <div className="help-block with-errors"></div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-4">
            <div className="form-group">
              <label htmlFor="inputAddress" className="control-label">Address *</label>
              <input type="text" id="inputAddress" className="form-control" placeholder="Address" valueLink={this.linkState('address')} required />
              <div className="help-block with-errors"></div>
            </div>
          </div>
          <div className="col-sm-4">
            <div className="form-group">
              <label htmlFor="inputCity" className="control-label">City *</label>
              <input type="text" id="inputCity" className="form-control" placeholder="City" valueLink={this.linkState('city')} required />
              <div className="help-block with-errors"></div>
            </div>
          </div>
          <div className="col-sm-2">
            <div className="form-group">
              <label htmlFor="inputPostalZip" className="control-label">Postal/Zip *</label>
              <input type="text" id="inputPostalZip" className="form-control" placeholder="Postal/Zip" valueLink={this.linkState('postal_zip')} required />
              <div className="help-block with-errors"></div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-4">
            <div className="form-group">
              <label htmlFor="selectCountry" className="control-label">Country *</label>
              <CountrySelect id="selectCountry" country={this.state.country} onChange={this.onCountryChange} />
              <div className="help-block with-errors"></div>
            </div>
          </div>
          <div className="col-sm-4">
            <div className="form-group">
              <label htmlFor="inputState" className="control-label">State/Province *</label>
              <StateField id="inputState" country={this.state.country} state={this.state.state} onChange={this.onStateChange} />
              <div className="help-block with-errors"></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
});

module.exports = General;
