var React = require('react');
var Router = require('react-router');
var Route = Router.Route;
var DefaultRoute = Router.DefaultRoute;
var NotFoundRoute = Router.NotFoundRoute;
var injectTapEventPlugin = require("react-tap-event-plugin");
var NProgress = require('nprogress');
var axios = require('axios');

require('bootstrap');
require('bootstrap-validator');
require('bootstrap-datepicker/dist/js/bootstrap-datepicker');

NProgress.configure({showSpinner: false});

axios.interceptors.request.use(function (config) {
  NProgress.start();
  return config;
}, function (err) {
  return Promise.reject(err);
});

axios.interceptors.response.use(function (res) {
  NProgress.done();
  return res;
}, function (err) {
  NProgress.done();
  return Promise.reject(err);
});

// Needed for onTouchTap
// Can go away when react 1.0 release
// Check this repo:
// https://github.com/zilverline/react-tap-event-plugin
injectTapEventPlugin();

// Public
var App = require('./infrastructure/components/App');
var Index = require('./public/components/Index');
var Login = require('./public/components/Login');
var About = require('./public/components/About');
var Faq = require('./public/components/Faq');
var PrivacyPolicy = require('./public/components/PrivacyPolicy');
var TermsAndConditions = require('./public/components/TermsAndConditions');

// Sign up
var RegistrationRequest = require('./signup/components/RegistrationRequest');
var VerifyAccessCode = require('./signup/components/VerifyAccessCode');
var AcceptPrivacyPolicy = require('./signup/components/AcceptPrivacyPolicy');
var AcceptTermsAndConditions = require('./signup/components/AcceptTermsAndConditions');
var SellerRegistration = require('./signup/components/SellerRegistration');
var SellerRegistrationComplete = require('./signup/components/SellerRegistrationComplete');
var BuyerRegistration = require('./signup/components/BuyerRegistration');
var BuyerRegistrationComplete = require('./signup/components/BuyerRegistrationComplete');
var ConsultantRegistration = require('./signup/components/ConsultantRegistration');
var ConsultantRegistrationComplete = require('./signup/components/ConsultantRegistrationComplete');

// Account Setup, Forgot Password, Password Reset, Login
var AccountSetup = require('./public/components/AccountSetup');
var ForgotPassword = require('./public/components/ForgotPassword');
var ResetPassword = require('./public/components/ResetPassword');
var SetPassword = require('./public/components/SetPassword');

// Not Found
var NotFound = require('./public/components/NotFound');

var routes = (
  <Route path="/" handler={App}>
    <DefaultRoute name="index" handler={Index} />
    <Route path="login" handler={Login} />
    <Route path="about" handler={About} />
    <Route path="faq" handler={Faq} />
    <Route path="privacy-policy" handler={PrivacyPolicy} />
    <Route path="terms-and-conditions" handler={TermsAndConditions} />

    <Route path="signup" handler={RegistrationRequest} />
    <Route name="verify-access-code" path="signup/verify-access-code/:mobileNumber" handler={VerifyAccessCode} />
    <Route name="accept-privacy-policy" path="signup/accept-privacy-policy/:accessToken" handler={AcceptPrivacyPolicy} />
    <Route name="accept-terms-and-conditions" path="signup/accept-terms-and-conditions/:accessToken" handler={AcceptTermsAndConditions} />
    <Route name="seller-registration" path="signup/seller-registration/:accessToken" handler={SellerRegistration} />
    <Route name="seller-registration-complete" path="/signup/seller-registration-complete" handler={SellerRegistrationComplete} />

    <Route name="buyer-registration" path="signup/buyer-registration/:accessToken" handler={BuyerRegistration} />
    <Route name="buyer-registration-complete" path="signup/buyer-registration-complete" handler={BuyerRegistrationComplete} />

    <Route name="consultant-registration" path="signup/consultant-registration/:accessToken" handler={ConsultantRegistration} />
    <Route name="consultant-registration-complete" path="signup/consultant-registration-complete" handler={ConsultantRegistrationComplete} />

    <Route name="account-setup" path="/account-setup/:passwordResetToken" handler={AccountSetup} />
    <Route name="forgot-password" path="/forgot-password" handler={ForgotPassword} />
    <Route name="reset-password" path="/reset-password/:passwordResetToken" handler={ResetPassword} />
    <Route name="set-password" path="/set-password/:setPasswordToken" handler={SetPassword} />

    <NotFoundRoute handler={NotFound} />
  </Route>
);

Router.run(routes, Router.HistoryLocation, function (Handler, state) {
  React.render(<Handler {...state} />, document.getElementById('afemax_container'));
});
