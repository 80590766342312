var React = require('react/addons');

var BillingOptions = React.createClass({
  mixins: [React.addons.LinkedStateMixin],

  getDefaultProps: function() {
    return {
      preference: null
    }
  },

  getInitialState: function() {
    return {
      preference: this.props.preference
    }
  },

  componentDidMount: function() {
    // Show please specify field if other is selected.
    if (this.props.preference === 'Open Invoice') {
      $(this.refs.openInvoice.getDOMNode()).prop('checked', true);
    } else if (this.props.preference === 'Cortex') {
      $(this.refs.cortex.getDOMNode()).prop('checked', true);
    } else if (this.props.preference === 'Email Invoice') {
      $(this.refs.emailInvoice.getDOMNode()).prop('checked', true);
    } else if (this.props.preference === 'Mail Invoice') {
      $(this.refs.mailInvoice.getDOMNode()).prop('checked', true);
    }
  },

  onChange: function(e) {
    var value = e.target.value;

    this.setState({
      preference: value
    });
  },

  render: function() {
    return (
      <div>
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <div className="radio">
                <label>
                  <input type="radio" ref="openInvoice" name="billing_option[]" onChange={this.onChange} value="Open Invoice" required /> Open Invoice
                </label>
              </div>
              <div className="radio">
                <label>
                  <input type="radio" ref="cortex" name="billing_option[]" onChange={this.onChange} value="Cortex" required /> Cortex
                </label>
              </div>
              <div className="radio">
                <label>
                  <input type="radio" ref="emailInvoice" name="billing_option[]" onChange={this.onChange} value="Email Invoice" required /> Email Invoice
                </label>
              </div>
              <div className="radio">
                <label>
                  <input type="radio" ref="mailInvoice" name="billing_option[]" onChange={this.onChange} value="Mail Invoice" required /> Mail Invoice
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>

    );
  }
});

module.exports = BillingOptions;
