var Reflux = require('reflux');
var SignupActions = require('../actions/SignupActions');

var mobileNumber = null;
var errors = {};

var RegistrationRequestStore = Reflux.createStore({

  listenables: [SignupActions],

  getMobileNumber: function() {
    return mobileNumber;
  },

  getErrors: function() {
    return errors;
  },

  onRequestCodeCompleted: function (res) {
    mobileNumber = res.data.mobileNumber;
    errors = {};

    this.trigger('REQUEST_CODE_COMPLETED');
  },

  onRequestCodeFailed: function (res) {
    mobileNumber = null;

    if (typeof res.data.errors !== 'undefined') {
      errors = res.data.errors;
    }

    this.trigger('REQUEST_CODE_FAILED');
  }

});

module.exports = RegistrationRequestStore;
