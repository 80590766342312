var errors = {};

module.exports = {

  setErrors: function(res) {
    if (typeof res.data.errors !== 'undefined') {
      errors = res.data.errors;
    }
  },

  getErrors: function() {
    return errors;
  },

  clearErrors: function() {
    errors = {};
  }

};
