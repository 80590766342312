var React = require('react/addons');
var DatePicker = require('../../shared/DatePicker');
var MultipleWCBs = require('./MultipleWCBs');

var Insurance = React.createClass({

  mixins: [React.addons.LinkedStateMixin],

  getDefaultProps: function() {
    return {
      organization: {
        in_commercial_general: null,
        in_commercial_general_expiry: null,
        in_third_party_automobile: null,
        in_third_party_automobile_expiry: null,
        in_aircraft: null,
        in_aircraft_expiry: null,
        in_pollution: null,
        in_pollution_expiry: null,
        in_fire: null,
        in_fire_expiry: null,
        in_errors_omissions: null,
        in_errors_omissions_expiry: null,
        in_wcb_coverage_number: null,
        in_wcb_expiry: null,
        wcbs: null
      }
    };
  },

  getInitialState: function() {
    return {
      in_commercial_general: this.props.organization.in_commercial_general,
      in_commercial_general_expiry: this.props.organization.in_commercial_general_expiry,
      in_third_party_automobile: this.props.organization.in_third_party_automobile,
      in_third_party_automobile_expiry: this.props.organization.in_third_party_automobile_expiry,
      in_aircraft: this.props.organization.in_aircraft,
      in_aircraft_expiry: this.props.organization.in_aircraft_expiry,
      in_pollution: this.props.organization.in_pollution,
      in_pollution_expiry: this.props.organization.in_pollution_expiry,
      in_fire: this.props.organization.in_fire,
      in_fire_expiry: this.props.organization.in_fire_expiry,
      in_errors_omissions: this.props.organization.in_errors_omissions,
      in_errors_omissions_expiry: this.props.organization.in_errors_omissions_expiry,
      in_wcb_coverage_number: this.props.organization.in_wcb_coverage_number,
      in_wcb_expiry: this.props.organization.in_wcb_expiry,
      wcbs: this.props.organization.wcbs
    };
  },

  handleExpiryChange: function(stateRef, value) {
    this.setState({[stateRef]: value});
  },



  render: function() {
    return (
      <div>
        <div className="row">
          <div className="col-sm-6">
            <div className="form-group">
              <label htmlFor="selectCommercialGeneral" className="control-label">Commercial general liability insurance *</label>
              <select
                id="selectCommercialGeneral"
                className="form-control"
                valueLink={this.linkState('in_commercial_general')}
                required>
                <option value="">-- Please select --</option>
                <option value="$500,000 - $1,000,000">$500,000 - $1,000,000</option>
                <option value="$1,000,000 minimum">$1,000,000 minimum</option>
                <option value="$2,000,000 minimum">$2,000,000 minimum</option>
                <option value="$5,000,000 minimum">$5,000,000 minimum</option>
                <option value=">$5 million">&gt; $5,000,000</option>
              </select>
              <div className="help-block with-errors"></div>
            </div>
          </div>
          <div className="col-sm-2">
            <DatePicker
              label="Expiry date"
              required={true}
              value={this.state.in_commercial_general_expiry}
              stateRef="in_commercial_general_expiry"
              initialClear={true}
              onChange={this.handleExpiryChange} />
          </div>
        </div>

        <div className="row">
          <div className="col-sm-6">
            <div className="form-group">
              <label htmlFor="selectThirdPartyAutomobile" className="control-label">Third party automobile liability insurance *</label>
              <select
                id="selectThirdPartyAutomobile"
                className="form-control"
                valueLink={this.linkState('in_third_party_automobile')}
                required>
                <option value="">-- Please select --</option>
                <option value="$500,000 - $1,000,000">$500,000 - $1,000,000</option>
                <option value="$1,000,000 minimum">$1,000,000 minimum</option>
                <option value="$2,000,000 minimum">$2,000,000 minimum</option>
                <option value="$5,000,000 minimum">$5,000,000 minimum</option>
                <option value=">$5 million">&gt; $5,000,000</option>
              </select>
              <div className="help-block with-errors"></div>
            </div>
          </div>
          <div className="col-sm-2">
            <DatePicker
              label="Expiry date"
              required={true}
              value={this.state.in_third_party_automobile_expiry}
              stateRef="in_third_party_automobile_expiry"
              initialClear={true}
              onChange={this.handleExpiryChange} />
          </div>
        </div>

        <div className="row">
          <div className="col-sm-6">
            <div className="form-group">
              <label htmlFor="selectAircraft" className="control-label">Aircraft liability insurance</label>
              <select
                id="selectAircraft"
                className="form-control"
                valueLink={this.linkState('in_aircraft')}>
                <option value="">No</option>
                <option value="$500,000 - $1,000,000">$500,000 - $1,000,000</option>
                <option value="$1,000,000 minimum">$1,000,000 minimum</option>
                <option value="$2,000,000 minimum">$2,000,000 minimum</option>
                <option value="$5,000,000 minimum">$5,000,000 minimum</option>
                <option value=">$5 million">&gt; $5,000,000</option>
              </select>
              <div className="help-block with-errors"></div>
            </div>
          </div>
          <div className="col-sm-2">
            <DatePicker
              label="Expiry date"
              required={this.state.in_aircraft}
              value={this.state.in_aircraft_expiry}
              stateRef="in_aircraft_expiry"
              initialClear={true}
              onChange={this.handleExpiryChange} />
          </div>
        </div>

        <div className="row">
          <div className="col-sm-6">
            <div className="form-group">
              <label htmlFor="selectPollution" className="control-label">Limited pollution insurance</label>
              <select
                id="selectPollution"
                className="form-control"
                valueLink={this.linkState('in_pollution')}>
                <option value="">No</option>
                <option value="$500,000 - $1,000,000">$500,000 - $1,000,000</option>
                <option value="$1,000,000 minimum">$1,000,000 minimum</option>
                <option value="$2,000,000 minimum">$2,000,000 minimum</option>
                <option value="$5,000,000 minimum">$5,000,000 minimum</option>
                <option value=">$5 million">&gt; $5,000,000</option>
              </select>
              <div className="help-block with-errors"></div>
            </div>
          </div>
          <div className="col-sm-2">
            <DatePicker
              label="Expiry date"
              required={this.state.in_pollution}
              value={this.state.in_pollution_expiry}
              stateRef="in_pollution_expiry"
              initialClear={true}
              onChange={this.handleExpiryChange} />
          </div>
        </div>

        <div className="row">
          <div className="col-sm-6">
            <div className="form-group">
              <label htmlFor="selectFire" className="control-label">Forest fire fighting expense insurance</label>
              <select
                id="selectFire"
                className="form-control"
                valueLink={this.linkState('in_fire')}>
                <option value="">No</option>
                <option value="$500,000 - $1,000,000">$500,000 - $1,000,000</option>
                <option value="$1,000,000 minimum">$1,000,000 minimum</option>
                <option value="$2,000,000 minimum">$2,000,000 minimum</option>
                <option value="$5,000,000 minimum">$5,000,000 minimum</option>
                <option value=">$5 million">&gt; $5,000,000</option>
              </select>
              <div className="help-block with-errors"></div>
            </div>
          </div>
          <div className="col-sm-2">
            <DatePicker
              label="Expiry date"
              required={this.state.in_fire}
              value={this.state.in_fire_expiry}
              stateRef="in_fire_expiry"
              initialClear={true}
              onChange={this.handleExpiryChange} />
          </div>
        </div>

        <div className="row">
          <div className="col-sm-6">
            <div className="form-group">
              <label htmlFor="selectErrorsOmissions" className="control-label">Errors and omissions insurance</label>
              <select
                id="selectErrorsOmissions"
                className="form-control"
                valueLink={this.linkState('in_errors_omissions')}>
                <option value="">No</option>
                <option value="$500,000 - $1,000,000">$500,000 - $1,000,000</option>
                <option value="$1,000,000 minimum">$1,000,000 minimum</option>
                <option value="$2,000,000 minimum">$2,000,000 minimum</option>
                <option value="$5,000,000 minimum">$5,000,000 minimum</option>
                <option value=">$5 million">&gt; $5,000,000</option>
              </select>
              <div className="help-block with-errors"></div>
            </div>
          </div>
          <div className="col-sm-2">
            <DatePicker
              label="Expiry date"
              required={this.state.in_errors_omissions}
              value={this.state.in_errors_omissions_expiry}
              stateRef="in_errors_omissions_expiry"
              initialClear={true}
              onChange={this.handleExpiryChange} />
          </div>
        </div>

        <MultipleWCBs
          ref="multipleWCBs"
          initialWCBs={this.state.wcbs} />
      </div>
    );
  }

});

module.exports = Insurance;
