var axios = require('axios');
var config = require('../config.js');

var ServiceAreaAPIUtils = {

  getServiceAreas: function() {
    return axios.get(config.apiRoot + '/service_areas');
  },

  createServiceArea: function(city, province, country) {
    return axios.post(config.apiRoot + '/service_areas', {
      city: city,
      province: province,
      country: country
    });
  },

  getServiceArea: function(id) {
    return axios.get(config.apiRoot + '/service_areas/' + id);
  },

  updateServiceArea: function(id, city, province, country) {
    return axios.put(config.apiRoot + '/service_areas/' + id, {
      city: city,
      province: province,
      country: country
    });
  },

  deleteServiceArea: function(id) {
    return axios.delete(config.apiRoot + '/service_areas/' + id);
  },

  getServiceAreaCheckboxes: function() {
    return axios.get(config.apiRoot + '/service_area_checkboxes')
  }

};

module.exports = ServiceAreaAPIUtils;
