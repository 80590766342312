module.exports = [
  {
    "zone":"Africa\/Abidjan",
    "offset":"UTC +00:00"
  },
  {
    "zone":"Africa\/Accra",
    "offset":"UTC +00:00"
  },
  {
    "zone":"Africa\/Addis_Ababa",
    "offset":"UTC +03:00"
  },
  {
    "zone":"Africa\/Algiers",
    "offset":"UTC +00:00"
  },
  {
    "zone":"Africa\/Asmara",
    "offset":"UTC +03:00"
  },
  {
    "zone":"Africa\/Bamako",
    "offset":"UTC +00:00"
  },
  {
    "zone":"Africa\/Bangui",
    "offset":"UTC +01:00"
  },
  {
    "zone":"Africa\/Banjul",
    "offset":"UTC +00:00"
  },
  {
    "zone":"Africa\/Bissau",
    "offset":"UTC -01:00"
  },
  {
    "zone":"Africa\/Blantyre",
    "offset":"UTC +02:00"
  },
  {
    "zone":"Africa\/Brazzaville",
    "offset":"UTC +01:00"
  },
  {
    "zone":"Africa\/Bujumbura",
    "offset":"UTC +02:00"
  },
  {
    "zone":"Africa\/Cairo",
    "offset":"UTC +02:00"
  },
  {
    "zone":"Africa\/Casablanca",
    "offset":"UTC +00:00"
  },
  {
    "zone":"Africa\/Ceuta",
    "offset":"UTC +00:00"
  },
  {
    "zone":"Africa\/Conakry",
    "offset":"UTC +00:00"
  },
  {
    "zone":"Africa\/Dakar",
    "offset":"UTC +00:00"
  },
  {
    "zone":"Africa\/Dar_es_Salaam",
    "offset":"UTC +03:00"
  },
  {
    "zone":"Africa\/Djibouti",
    "offset":"UTC +03:00"
  },
  {
    "zone":"Africa\/Douala",
    "offset":"UTC +01:00"
  },
  {
    "zone":"Africa\/El_Aaiun",
    "offset":"UTC -01:00"
  },
  {
    "zone":"Africa\/Freetown",
    "offset":"UTC +00:00"
  },
  {
    "zone":"Africa\/Gaborone",
    "offset":"UTC +02:00"
  },
  {
    "zone":"Africa\/Harare",
    "offset":"UTC +02:00"
  },
  {
    "zone":"Africa\/Johannesburg",
    "offset":"UTC +02:00"
  },
  {
    "zone":"Africa\/Juba",
    "offset":"UTC +02:00"
  },
  {
    "zone":"Africa\/Kampala",
    "offset":"UTC +03:00"
  },
  {
    "zone":"Africa\/Khartoum",
    "offset":"UTC +02:00"
  },
  {
    "zone":"Africa\/Kigali",
    "offset":"UTC +02:00"
  },
  {
    "zone":"Africa\/Kinshasa",
    "offset":"UTC +01:00"
  },
  {
    "zone":"Africa\/Lagos",
    "offset":"UTC +01:00"
  },
  {
    "zone":"Africa\/Libreville",
    "offset":"UTC +01:00"
  },
  {
    "zone":"Africa\/Lome",
    "offset":"UTC +00:00"
  },
  {
    "zone":"Africa\/Luanda",
    "offset":"UTC +01:00"
  },
  {
    "zone":"Africa\/Lubumbashi",
    "offset":"UTC +02:00"
  },
  {
    "zone":"Africa\/Lusaka",
    "offset":"UTC +02:00"
  },
  {
    "zone":"Africa\/Malabo",
    "offset":"UTC +01:00"
  },
  {
    "zone":"Africa\/Maputo",
    "offset":"UTC +02:00"
  },
  {
    "zone":"Africa\/Maseru",
    "offset":"UTC +02:00"
  },
  {
    "zone":"Africa\/Mbabane",
    "offset":"UTC +02:00"
  },
  {
    "zone":"Africa\/Mogadishu",
    "offset":"UTC +03:00"
  },
  {
    "zone":"Africa\/Monrovia",
    "offset":"UTC -00:44"
  },
  {
    "zone":"Africa\/Nairobi",
    "offset":"UTC +03:00"
  },
  {
    "zone":"Africa\/Ndjamena",
    "offset":"UTC +01:00"
  },
  {
    "zone":"Africa\/Niamey",
    "offset":"UTC +01:00"
  },
  {
    "zone":"Africa\/Nouakchott",
    "offset":"UTC +00:00"
  },
  {
    "zone":"Africa\/Ouagadougou",
    "offset":"UTC +00:00"
  },
  {
    "zone":"Africa\/Porto-Novo",
    "offset":"UTC +01:00"
  },
  {
    "zone":"Africa\/Sao_Tome",
    "offset":"UTC +00:00"
  },
  {
    "zone":"Africa\/Tripoli",
    "offset":"UTC +02:00"
  },
  {
    "zone":"Africa\/Tunis",
    "offset":"UTC +01:00"
  },
  {
    "zone":"Africa\/Windhoek",
    "offset":"UTC +02:00"
  },
  {
    "zone":"America\/Adak",
    "offset":"UTC -11:00"
  },
  {
    "zone":"America\/Anchorage",
    "offset":"UTC -10:00"
  },
  {
    "zone":"America\/Anguilla",
    "offset":"UTC -04:00"
  },
  {
    "zone":"America\/Antigua",
    "offset":"UTC -04:00"
  },
  {
    "zone":"America\/Araguaina",
    "offset":"UTC -03:00"
  },
  {
    "zone":"America\/Argentina\/Buenos_Aires",
    "offset":"UTC -03:00"
  },
  {
    "zone":"America\/Argentina\/Catamarca",
    "offset":"UTC -03:00"
  },
  {
    "zone":"America\/Argentina\/Cordoba",
    "offset":"UTC -03:00"
  },
  {
    "zone":"America\/Argentina\/Jujuy",
    "offset":"UTC -03:00"
  },
  {
    "zone":"America\/Argentina\/La_Rioja",
    "offset":"UTC -03:00"
  },
  {
    "zone":"America\/Argentina\/Mendoza",
    "offset":"UTC -03:00"
  },
  {
    "zone":"America\/Argentina\/Rio_Gallegos",
    "offset":"UTC -03:00"
  },
  {
    "zone":"America\/Argentina\/Salta",
    "offset":"UTC -03:00"
  },
  {
    "zone":"America\/Argentina\/San_Juan",
    "offset":"UTC -03:00"
  },
  {
    "zone":"America\/Argentina\/San_Luis",
    "offset":"UTC -03:00"
  },
  {
    "zone":"America\/Argentina\/Tucuman",
    "offset":"UTC -03:00"
  },
  {
    "zone":"America\/Argentina\/Ushuaia",
    "offset":"UTC -03:00"
  },
  {
    "zone":"America\/Aruba",
    "offset":"UTC -04:00"
  },
  {
    "zone":"America\/Asuncion",
    "offset":"UTC -04:00"
  },
  {
    "zone":"America\/Atikokan",
    "offset":"UTC -05:00"
  },
  {
    "zone":"America\/Bahia",
    "offset":"UTC -03:00"
  },
  {
    "zone":"America\/Bahia_Banderas",
    "offset":"UTC -08:00"
  },
  {
    "zone":"America\/Barbados",
    "offset":"UTC -04:00"
  },
  {
    "zone":"America\/Belem",
    "offset":"UTC -03:00"
  },
  {
    "zone":"America\/Belize",
    "offset":"UTC -06:00"
  },
  {
    "zone":"America\/Blanc-Sablon",
    "offset":"UTC -04:00"
  },
  {
    "zone":"America\/Boa_Vista",
    "offset":"UTC -04:00"
  },
  {
    "zone":"America\/Bogota",
    "offset":"UTC -05:00"
  },
  {
    "zone":"America\/Boise",
    "offset":"UTC -07:00"
  },
  {
    "zone":"America\/Cambridge_Bay",
    "offset":"UTC -07:00"
  },
  {
    "zone":"America\/Campo_Grande",
    "offset":"UTC -04:00"
  },
  {
    "zone":"America\/Cancun",
    "offset":"UTC -06:00"
  },
  {
    "zone":"America\/Caracas",
    "offset":"UTC -04:00"
  },
  {
    "zone":"America\/Cayenne",
    "offset":"UTC -03:00"
  },
  {
    "zone":"America\/Cayman",
    "offset":"UTC -05:00"
  },
  {
    "zone":"America\/Chicago",
    "offset":"UTC -06:00"
  },
  {
    "zone":"America\/Chihuahua",
    "offset":"UTC -06:00"
  },
  {
    "zone":"America\/Costa_Rica",
    "offset":"UTC -06:00"
  },
  {
    "zone":"America\/Creston",
    "offset":"UTC -07:00"
  },
  {
    "zone":"America\/Cuiaba",
    "offset":"UTC -04:00"
  },
  {
    "zone":"America\/Curacao",
    "offset":"UTC -04:00"
  },
  {
    "zone":"America\/Danmarkshavn",
    "offset":"UTC -03:00"
  },
  {
    "zone":"America\/Dawson",
    "offset":"UTC -09:00"
  },
  {
    "zone":"America\/Dawson_Creek",
    "offset":"UTC -08:00"
  },
  {
    "zone":"America\/Denver",
    "offset":"UTC -07:00"
  },
  {
    "zone":"America\/Detroit",
    "offset":"UTC -05:00"
  },
  {
    "zone":"America\/Dominica",
    "offset":"UTC -04:00"
  },
  {
    "zone":"America\/Edmonton",
    "offset":"UTC -07:00"
  },
  {
    "zone":"America\/Eirunepe",
    "offset":"UTC -05:00"
  },
  {
    "zone":"America\/El_Salvador",
    "offset":"UTC -06:00"
  },
  {
    "zone":"America\/Fortaleza",
    "offset":"UTC -03:00"
  },
  {
    "zone":"America\/Glace_Bay",
    "offset":"UTC -04:00"
  },
  {
    "zone":"America\/Godthab",
    "offset":"UTC -03:00"
  },
  {
    "zone":"America\/Goose_Bay",
    "offset":"UTC -04:00"
  },
  {
    "zone":"America\/Grand_Turk",
    "offset":"UTC -05:00"
  },
  {
    "zone":"America\/Grenada",
    "offset":"UTC -04:00"
  },
  {
    "zone":"America\/Guadeloupe",
    "offset":"UTC -04:00"
  },
  {
    "zone":"America\/Guatemala",
    "offset":"UTC -06:00"
  },
  {
    "zone":"America\/Guayaquil",
    "offset":"UTC -05:00"
  },
  {
    "zone":"America\/Guyana",
    "offset":"UTC -03:45"
  },
  {
    "zone":"America\/Halifax",
    "offset":"UTC -04:00"
  },
  {
    "zone":"America\/Havana",
    "offset":"UTC -05:00"
  },
  {
    "zone":"America\/Hermosillo",
    "offset":"UTC -08:00"
  },
  {
    "zone":"America\/Indiana\/Indianapolis",
    "offset":"UTC -05:00"
  },
  {
    "zone":"America\/Indiana\/Knox",
    "offset":"UTC -06:00"
  },
  {
    "zone":"America\/Indiana\/Marengo",
    "offset":"UTC -05:00"
  },
  {
    "zone":"America\/Indiana\/Petersburg",
    "offset":"UTC -06:00"
  },
  {
    "zone":"America\/Indiana\/Tell_City",
    "offset":"UTC -05:00"
  },
  {
    "zone":"America\/Indiana\/Vevay",
    "offset":"UTC -05:00"
  },
  {
    "zone":"America\/Indiana\/Vincennes",
    "offset":"UTC -05:00"
  },
  {
    "zone":"America\/Indiana\/Winamac",
    "offset":"UTC -05:00"
  },
  {
    "zone":"America\/Inuvik",
    "offset":"UTC -08:00"
  },
  {
    "zone":"America\/Iqaluit",
    "offset":"UTC -05:00"
  },
  {
    "zone":"America\/Jamaica",
    "offset":"UTC -05:00"
  },
  {
    "zone":"America\/Juneau",
    "offset":"UTC -08:00"
  },
  {
    "zone":"America\/Kentucky\/Louisville",
    "offset":"UTC -05:00"
  },
  {
    "zone":"America\/Kentucky\/Monticello",
    "offset":"UTC -06:00"
  },
  {
    "zone":"America\/Kralendijk",
    "offset":"UTC -04:00"
  },
  {
    "zone":"America\/La_Paz",
    "offset":"UTC -04:00"
  },
  {
    "zone":"America\/Lima",
    "offset":"UTC -05:00"
  },
  {
    "zone":"America\/Los_Angeles",
    "offset":"UTC -08:00"
  },
  {
    "zone":"America\/Lower_Princes",
    "offset":"UTC -04:00"
  },
  {
    "zone":"America\/Maceio",
    "offset":"UTC -03:00"
  },
  {
    "zone":"America\/Managua",
    "offset":"UTC -06:00"
  },
  {
    "zone":"America\/Manaus",
    "offset":"UTC -04:00"
  },
  {
    "zone":"America\/Marigot",
    "offset":"UTC -04:00"
  },
  {
    "zone":"America\/Martinique",
    "offset":"UTC -04:00"
  },
  {
    "zone":"America\/Matamoros",
    "offset":"UTC -06:00"
  },
  {
    "zone":"America\/Mazatlan",
    "offset":"UTC -08:00"
  },
  {
    "zone":"America\/Menominee",
    "offset":"UTC -05:00"
  },
  {
    "zone":"America\/Merida",
    "offset":"UTC -06:00"
  },
  {
    "zone":"America\/Metlakatla",
    "offset":"UTC -08:00"
  },
  {
    "zone":"America\/Mexico_City",
    "offset":"UTC -06:00"
  },
  {
    "zone":"America\/Miquelon",
    "offset":"UTC -04:00"
  },
  {
    "zone":"America\/Moncton",
    "offset":"UTC -04:00"
  },
  {
    "zone":"America\/Monterrey",
    "offset":"UTC -06:00"
  },
  {
    "zone":"America\/Montevideo",
    "offset":"UTC -03:00"
  },
  {
    "zone":"America\/Montreal",
    "offset":"UTC -05:00"
  },
  {
    "zone":"America\/Montserrat",
    "offset":"UTC -04:00"
  },
  {
    "zone":"America\/Nassau",
    "offset":"UTC -05:00"
  },
  {
    "zone":"America\/New_York",
    "offset":"UTC -05:00"
  },
  {
    "zone":"America\/Nipigon",
    "offset":"UTC -05:00"
  },
  {
    "zone":"America\/Nome",
    "offset":"UTC -11:00"
  },
  {
    "zone":"America\/Noronha",
    "offset":"UTC -02:00"
  },
  {
    "zone":"America\/North_Dakota\/Beulah",
    "offset":"UTC -07:00"
  },
  {
    "zone":"America\/North_Dakota\/Center",
    "offset":"UTC -07:00"
  },
  {
    "zone":"America\/North_Dakota\/New_Salem",
    "offset":"UTC -07:00"
  },
  {
    "zone":"America\/Ojinaga",
    "offset":"UTC -06:00"
  },
  {
    "zone":"America\/Panama",
    "offset":"UTC -05:00"
  },
  {
    "zone":"America\/Pangnirtung",
    "offset":"UTC -04:00"
  },
  {
    "zone":"America\/Paramaribo",
    "offset":"UTC -03:30"
  },
  {
    "zone":"America\/Phoenix",
    "offset":"UTC -07:00"
  },
  {
    "zone":"America\/Port-au-Prince",
    "offset":"UTC -05:00"
  },
  {
    "zone":"America\/Port_of_Spain",
    "offset":"UTC -04:00"
  },
  {
    "zone":"America\/Porto_Velho",
    "offset":"UTC -04:00"
  },
  {
    "zone":"America\/Puerto_Rico",
    "offset":"UTC -04:00"
  },
  {
    "zone":"America\/Rainy_River",
    "offset":"UTC -06:00"
  },
  {
    "zone":"America\/Rankin_Inlet",
    "offset":"UTC -06:00"
  },
  {
    "zone":"America\/Recife",
    "offset":"UTC -03:00"
  },
  {
    "zone":"America\/Regina",
    "offset":"UTC -06:00"
  },
  {
    "zone":"America\/Resolute",
    "offset":"UTC -06:00"
  },
  {
    "zone":"America\/Rio_Branco",
    "offset":"UTC -05:00"
  },
  {
    "zone":"America\/Santa_Isabel",
    "offset":"UTC -08:00"
  },
  {
    "zone":"America\/Santarem",
    "offset":"UTC -04:00"
  },
  {
    "zone":"America\/Santiago",
    "offset":"UTC -03:00"
  },
  {
    "zone":"America\/Santo_Domingo",
    "offset":"UTC -04:30"
  },
  {
    "zone":"America\/Sao_Paulo",
    "offset":"UTC -03:00"
  },
  {
    "zone":"America\/Scoresbysund",
    "offset":"UTC -02:00"
  },
  {
    "zone":"America\/Shiprock",
    "offset":"UTC -07:00"
  },
  {
    "zone":"America\/Sitka",
    "offset":"UTC -08:00"
  },
  {
    "zone":"America\/St_Barthelemy",
    "offset":"UTC -04:00"
  },
  {
    "zone":"America\/St_Johns",
    "offset":"UTC -03:30"
  },
  {
    "zone":"America\/St_Kitts",
    "offset":"UTC -04:00"
  },
  {
    "zone":"America\/St_Lucia",
    "offset":"UTC -04:00"
  },
  {
    "zone":"America\/St_Thomas",
    "offset":"UTC -04:00"
  },
  {
    "zone":"America\/St_Vincent",
    "offset":"UTC -04:00"
  },
  {
    "zone":"America\/Swift_Current",
    "offset":"UTC -07:00"
  },
  {
    "zone":"America\/Tegucigalpa",
    "offset":"UTC -06:00"
  },
  {
    "zone":"America\/Thule",
    "offset":"UTC -04:00"
  },
  {
    "zone":"America\/Thunder_Bay",
    "offset":"UTC -05:00"
  },
  {
    "zone":"America\/Tijuana",
    "offset":"UTC -08:00"
  },
  {
    "zone":"America\/Toronto",
    "offset":"UTC -05:00"
  },
  {
    "zone":"America\/Tortola",
    "offset":"UTC -04:00"
  },
  {
    "zone":"America\/Vancouver",
    "offset":"UTC -08:00"
  },
  {
    "zone":"America\/Whitehorse",
    "offset":"UTC -08:00"
  },
  {
    "zone":"America\/Winnipeg",
    "offset":"UTC -06:00"
  },
  {
    "zone":"America\/Yakutat",
    "offset":"UTC -09:00"
  },
  {
    "zone":"America\/Yellowknife",
    "offset":"UTC -07:00"
  },
  {
    "zone":"Antarctica\/Casey",
    "offset":"UTC +08:00"
  },
  {
    "zone":"Antarctica\/Davis",
    "offset":"UTC +07:00"
  },
  {
    "zone":"Antarctica\/DumontDUrville",
    "offset":"UTC +10:00"
  },
  {
    "zone":"Antarctica\/Macquarie",
    "offset":"UTC +11:00"
  },
  {
    "zone":"Antarctica\/Mawson",
    "offset":"UTC +06:00"
  },
  {
    "zone":"Antarctica\/McMurdo",
    "offset":"UTC +12:00"
  },
  {
    "zone":"Antarctica\/Palmer",
    "offset":"UTC -03:00"
  },
  {
    "zone":"Antarctica\/Rothera",
    "offset":"UTC +00:00"
  },
  {
    "zone":"Antarctica\/South_Pole",
    "offset":"UTC +12:00"
  },
  {
    "zone":"Antarctica\/Syowa",
    "offset":"UTC +03:00"
  },
  {
    "zone":"Antarctica\/Vostok",
    "offset":"UTC +06:00"
  },
  {
    "zone":"Arctic\/Longyearbyen",
    "offset":"UTC +01:00"
  },
  {
    "zone":"Asia\/Aden",
    "offset":"UTC +03:00"
  },
  {
    "zone":"Asia\/Almaty",
    "offset":"UTC +06:00"
  },
  {
    "zone":"Asia\/Amman",
    "offset":"UTC +02:00"
  },
  {
    "zone":"Asia\/Anadyr",
    "offset":"UTC +13:00"
  },
  {
    "zone":"Asia\/Aqtau",
    "offset":"UTC +05:00"
  },
  {
    "zone":"Asia\/Aqtobe",
    "offset":"UTC +05:00"
  },
  {
    "zone":"Asia\/Ashgabat",
    "offset":"UTC +05:00"
  },
  {
    "zone":"Asia\/Baghdad",
    "offset":"UTC +03:00"
  },
  {
    "zone":"Asia\/Bahrain",
    "offset":"UTC +04:00"
  },
  {
    "zone":"Asia\/Baku",
    "offset":"UTC +04:00"
  },
  {
    "zone":"Asia\/Bangkok",
    "offset":"UTC +07:00"
  },
  {
    "zone":"Asia\/Beirut",
    "offset":"UTC +02:00"
  },
  {
    "zone":"Asia\/Bishkek",
    "offset":"UTC +06:00"
  },
  {
    "zone":"Asia\/Brunei",
    "offset":"UTC +08:00"
  },
  {
    "zone":"Asia\/Choibalsan",
    "offset":"UTC +07:00"
  },
  {
    "zone":"Asia\/Chongqing",
    "offset":"UTC +07:00"
  },
  {
    "zone":"Asia\/Colombo",
    "offset":"UTC +05:30"
  },
  {
    "zone":"Asia\/Damascus",
    "offset":"UTC +02:00"
  },
  {
    "zone":"Asia\/Dhaka",
    "offset":"UTC +06:00"
  },
  {
    "zone":"Asia\/Dili",
    "offset":"UTC +09:00"
  },
  {
    "zone":"Asia\/Dubai",
    "offset":"UTC +04:00"
  },
  {
    "zone":"Asia\/Dushanbe",
    "offset":"UTC +06:00"
  },
  {
    "zone":"Asia\/Gaza",
    "offset":"UTC +02:00"
  },
  {
    "zone":"Asia\/Harbin",
    "offset":"UTC +08:30"
  },
  {
    "zone":"Asia\/Hebron",
    "offset":"UTC +02:00"
  },
  {
    "zone":"Asia\/Ho_Chi_Minh",
    "offset":"UTC +07:00"
  },
  {
    "zone":"Asia\/Hong_Kong",
    "offset":"UTC +08:00"
  },
  {
    "zone":"Asia\/Hovd",
    "offset":"UTC +06:00"
  },
  {
    "zone":"Asia\/Irkutsk",
    "offset":"UTC +08:00"
  },
  {
    "zone":"Asia\/Jakarta",
    "offset":"UTC +07:00"
  },
  {
    "zone":"Asia\/Jayapura",
    "offset":"UTC +09:00"
  },
  {
    "zone":"Asia\/Jerusalem",
    "offset":"UTC +02:00"
  },
  {
    "zone":"Asia\/Kabul",
    "offset":"UTC +04:30"
  },
  {
    "zone":"Asia\/Kamchatka",
    "offset":"UTC +12:00"
  },
  {
    "zone":"Asia\/Karachi",
    "offset":"UTC +05:00"
  },
  {
    "zone":"Asia\/Kashgar",
    "offset":"UTC +05:00"
  },
  {
    "zone":"Asia\/Kathmandu",
    "offset":"UTC +05:30"
  },
  {
    "zone":"Asia\/Kolkata",
    "offset":"UTC +05:30"
  },
  {
    "zone":"Asia\/Krasnoyarsk",
    "offset":"UTC +07:00"
  },
  {
    "zone":"Asia\/Kuala_Lumpur",
    "offset":"UTC +07:30"
  },
  {
    "zone":"Asia\/Kuching",
    "offset":"UTC +08:00"
  },
  {
    "zone":"Asia\/Kuwait",
    "offset":"UTC +03:00"
  },
  {
    "zone":"Asia\/Macau",
    "offset":"UTC +08:00"
  },
  {
    "zone":"Asia\/Magadan",
    "offset":"UTC +11:00"
  },
  {
    "zone":"Asia\/Makassar",
    "offset":"UTC +08:00"
  },
  {
    "zone":"Asia\/Manila",
    "offset":"UTC +08:00"
  },
  {
    "zone":"Asia\/Muscat",
    "offset":"UTC +04:00"
  },
  {
    "zone":"Asia\/Nicosia",
    "offset":"UTC +02:00"
  },
  {
    "zone":"Asia\/Novokuznetsk",
    "offset":"UTC +07:00"
  },
  {
    "zone":"Asia\/Novosibirsk",
    "offset":"UTC +07:00"
  },
  {
    "zone":"Asia\/Omsk",
    "offset":"UTC +06:00"
  },
  {
    "zone":"Asia\/Oral",
    "offset":"UTC +05:00"
  },
  {
    "zone":"Asia\/Phnom_Penh",
    "offset":"UTC +07:00"
  },
  {
    "zone":"Asia\/Pontianak",
    "offset":"UTC +08:00"
  },
  {
    "zone":"Asia\/Pyongyang",
    "offset":"UTC +09:00"
  },
  {
    "zone":"Asia\/Qatar",
    "offset":"UTC +04:00"
  },
  {
    "zone":"Asia\/Qyzylorda",
    "offset":"UTC +05:00"
  },
  {
    "zone":"Asia\/Rangoon",
    "offset":"UTC +06:30"
  },
  {
    "zone":"Asia\/Riyadh",
    "offset":"UTC +03:00"
  },
  {
    "zone":"Asia\/Sakhalin",
    "offset":"UTC +11:00"
  },
  {
    "zone":"Asia\/Samarkand",
    "offset":"UTC +05:00"
  },
  {
    "zone":"Asia\/Seoul",
    "offset":"UTC +09:00"
  },
  {
    "zone":"Asia\/Shanghai",
    "offset":"UTC +08:00"
  },
  {
    "zone":"Asia\/Singapore",
    "offset":"UTC +07:30"
  },
  {
    "zone":"Asia\/Taipei",
    "offset":"UTC +08:00"
  },
  {
    "zone":"Asia\/Tashkent",
    "offset":"UTC +06:00"
  },
  {
    "zone":"Asia\/Tbilisi",
    "offset":"UTC +04:00"
  },
  {
    "zone":"Asia\/Tehran",
    "offset":"UTC +03:30"
  },
  {
    "zone":"Asia\/Thimphu",
    "offset":"UTC +05:30"
  },
  {
    "zone":"Asia\/Tokyo",
    "offset":"UTC +09:00"
  },
  {
    "zone":"Asia\/Ulaanbaatar",
    "offset":"UTC +07:00"
  },
  {
    "zone":"Asia\/Urumqi",
    "offset":"UTC +06:00"
  },
  {
    "zone":"Asia\/Vientiane",
    "offset":"UTC +07:00"
  },
  {
    "zone":"Asia\/Vladivostok",
    "offset":"UTC +10:00"
  },
  {
    "zone":"Asia\/Yakutsk",
    "offset":"UTC +09:00"
  },
  {
    "zone":"Asia\/Yekaterinburg",
    "offset":"UTC +05:00"
  },
  {
    "zone":"Asia\/Yerevan",
    "offset":"UTC +04:00"
  },
  {
    "zone":"Atlantic\/Azores",
    "offset":"UTC -01:00"
  },
  {
    "zone":"Atlantic\/Bermuda",
    "offset":"UTC -04:00"
  },
  {
    "zone":"Atlantic\/Canary",
    "offset":"UTC +00:00"
  },
  {
    "zone":"Atlantic\/Cape_Verde",
    "offset":"UTC -02:00"
  },
  {
    "zone":"Atlantic\/Faroe",
    "offset":"UTC +00:00"
  },
  {
    "zone":"Atlantic\/Madeira",
    "offset":"UTC +00:00"
  },
  {
    "zone":"Atlantic\/Reykjavik",
    "offset":"UTC +00:00"
  },
  {
    "zone":"Atlantic\/South_Georgia",
    "offset":"UTC -02:00"
  },
  {
    "zone":"Atlantic\/St_Helena",
    "offset":"UTC +00:00"
  },
  {
    "zone":"Atlantic\/Stanley",
    "offset":"UTC -04:00"
  },
  {
    "zone":"Australia\/Adelaide",
    "offset":"UTC +09:30"
  },
  {
    "zone":"Australia\/Brisbane",
    "offset":"UTC +10:00"
  },
  {
    "zone":"Australia\/Broken_Hill",
    "offset":"UTC +09:30"
  },
  {
    "zone":"Australia\/Currie",
    "offset":"UTC +10:00"
  },
  {
    "zone":"Australia\/Darwin",
    "offset":"UTC +09:30"
  },
  {
    "zone":"Australia\/Eucla",
    "offset":"UTC +08:45"
  },
  {
    "zone":"Australia\/Hobart",
    "offset":"UTC +11:00"
  },
  {
    "zone":"Australia\/Lindeman",
    "offset":"UTC +10:00"
  },
  {
    "zone":"Australia\/Lord_Howe",
    "offset":"UTC +10:00"
  },
  {
    "zone":"Australia\/Melbourne",
    "offset":"UTC +10:00"
  },
  {
    "zone":"Australia\/Perth",
    "offset":"UTC +08:00"
  },
  {
    "zone":"Australia\/Sydney",
    "offset":"UTC +10:00"
  },
  {
    "zone":"Europe\/Amsterdam",
    "offset":"UTC +01:00"
  },
  {
    "zone":"Europe\/Andorra",
    "offset":"UTC +01:00"
  },
  {
    "zone":"Europe\/Athens",
    "offset":"UTC +02:00"
  },
  {
    "zone":"Europe\/Belgrade",
    "offset":"UTC +01:00"
  },
  {
    "zone":"Europe\/Berlin",
    "offset":"UTC +01:00"
  },
  {
    "zone":"Europe\/Bratislava",
    "offset":"UTC +01:00"
  },
  {
    "zone":"Europe\/Brussels",
    "offset":"UTC +01:00"
  },
  {
    "zone":"Europe\/Bucharest",
    "offset":"UTC +02:00"
  },
  {
    "zone":"Europe\/Budapest",
    "offset":"UTC +01:00"
  },
  {
    "zone":"Europe\/Chisinau",
    "offset":"UTC +03:00"
  },
  {
    "zone":"Europe\/Copenhagen",
    "offset":"UTC +01:00"
  },
  {
    "zone":"Europe\/Dublin",
    "offset":"UTC +01:00"
  },
  {
    "zone":"Europe\/Gibraltar",
    "offset":"UTC +01:00"
  },
  {
    "zone":"Europe\/Guernsey",
    "offset":"UTC +01:00"
  },
  {
    "zone":"Europe\/Helsinki",
    "offset":"UTC +02:00"
  },
  {
    "zone":"Europe\/Isle_of_Man",
    "offset":"UTC +01:00"
  },
  {
    "zone":"Europe\/Istanbul",
    "offset":"UTC +02:00"
  },
  {
    "zone":"Europe\/Jersey",
    "offset":"UTC +01:00"
  },
  {
    "zone":"Europe\/Kaliningrad",
    "offset":"UTC +03:00"
  },
  {
    "zone":"Europe\/Kiev",
    "offset":"UTC +03:00"
  },
  {
    "zone":"Europe\/Lisbon",
    "offset":"UTC +01:00"
  },
  {
    "zone":"Europe\/Ljubljana",
    "offset":"UTC +01:00"
  },
  {
    "zone":"Europe\/London",
    "offset":"UTC +01:00"
  },
  {
    "zone":"Europe\/Luxembourg",
    "offset":"UTC +01:00"
  },
  {
    "zone":"Europe\/Madrid",
    "offset":"UTC +01:00"
  },
  {
    "zone":"Europe\/Malta",
    "offset":"UTC +01:00"
  },
  {
    "zone":"Europe\/Mariehamn",
    "offset":"UTC +02:00"
  },
  {
    "zone":"Europe\/Minsk",
    "offset":"UTC +03:00"
  },
  {
    "zone":"Europe\/Monaco",
    "offset":"UTC +01:00"
  },
  {
    "zone":"Europe\/Moscow",
    "offset":"UTC +03:00"
  },
  {
    "zone":"Europe\/Oslo",
    "offset":"UTC +01:00"
  },
  {
    "zone":"Europe\/Paris",
    "offset":"UTC +01:00"
  },
  {
    "zone":"Europe\/Podgorica",
    "offset":"UTC +01:00"
  },
  {
    "zone":"Europe\/Prague",
    "offset":"UTC +01:00"
  },
  {
    "zone":"Europe\/Riga",
    "offset":"UTC +03:00"
  },
  {
    "zone":"Europe\/Rome",
    "offset":"UTC +01:00"
  },
  {
    "zone":"Europe\/Samara",
    "offset":"UTC +04:00"
  },
  {
    "zone":"Europe\/San_Marino",
    "offset":"UTC +01:00"
  },
  {
    "zone":"Europe\/Sarajevo",
    "offset":"UTC +01:00"
  },
  {
    "zone":"Europe\/Simferopol",
    "offset":"UTC +03:00"
  },
  {
    "zone":"Europe\/Skopje",
    "offset":"UTC +01:00"
  },
  {
    "zone":"Europe\/Sofia",
    "offset":"UTC +02:00"
  },
  {
    "zone":"Europe\/Stockholm",
    "offset":"UTC +01:00"
  },
  {
    "zone":"Europe\/Tallinn",
    "offset":"UTC +03:00"
  },
  {
    "zone":"Europe\/Tirane",
    "offset":"UTC +01:00"
  },
  {
    "zone":"Europe\/Uzhgorod",
    "offset":"UTC +03:00"
  },
  {
    "zone":"Europe\/Vaduz",
    "offset":"UTC +01:00"
  },
  {
    "zone":"Europe\/Vatican",
    "offset":"UTC +01:00"
  },
  {
    "zone":"Europe\/Vienna",
    "offset":"UTC +01:00"
  },
  {
    "zone":"Europe\/Vilnius",
    "offset":"UTC +03:00"
  },
  {
    "zone":"Europe\/Volgograd",
    "offset":"UTC +04:00"
  },
  {
    "zone":"Europe\/Warsaw",
    "offset":"UTC +01:00"
  },
  {
    "zone":"Europe\/Zagreb",
    "offset":"UTC +01:00"
  },
  {
    "zone":"Europe\/Zaporozhye",
    "offset":"UTC +03:00"
  },
  {
    "zone":"Europe\/Zurich",
    "offset":"UTC +01:00"
  },
  {
    "zone":"Indian\/Antananarivo",
    "offset":"UTC +03:00"
  },
  {
    "zone":"Indian\/Chagos",
    "offset":"UTC +05:00"
  },
  {
    "zone":"Indian\/Christmas",
    "offset":"UTC +07:00"
  },
  {
    "zone":"Indian\/Cocos",
    "offset":"UTC +06:30"
  },
  {
    "zone":"Indian\/Comoro",
    "offset":"UTC +03:00"
  },
  {
    "zone":"Indian\/Kerguelen",
    "offset":"UTC +05:00"
  },
  {
    "zone":"Indian\/Mahe",
    "offset":"UTC +04:00"
  },
  {
    "zone":"Indian\/Maldives",
    "offset":"UTC +05:00"
  },
  {
    "zone":"Indian\/Mauritius",
    "offset":"UTC +04:00"
  },
  {
    "zone":"Indian\/Mayotte",
    "offset":"UTC +03:00"
  },
  {
    "zone":"Indian\/Reunion",
    "offset":"UTC +04:00"
  },
  {
    "zone":"Pacific\/Apia",
    "offset":"UTC -11:00"
  },
  {
    "zone":"Pacific\/Auckland",
    "offset":"UTC +12:00"
  },
  {
    "zone":"Pacific\/Chatham",
    "offset":"UTC +12:45"
  },
  {
    "zone":"Pacific\/Chuuk",
    "offset":"UTC +10:00"
  },
  {
    "zone":"Pacific\/Easter",
    "offset":"UTC -06:00"
  },
  {
    "zone":"Pacific\/Efate",
    "offset":"UTC +11:00"
  },
  {
    "zone":"Pacific\/Enderbury",
    "offset":"UTC -12:00"
  },
  {
    "zone":"Pacific\/Fakaofo",
    "offset":"UTC -10:00"
  },
  {
    "zone":"Pacific\/Fiji",
    "offset":"UTC +12:00"
  },
  {
    "zone":"Pacific\/Funafuti",
    "offset":"UTC +12:00"
  },
  {
    "zone":"Pacific\/Galapagos",
    "offset":"UTC -05:00"
  },
  {
    "zone":"Pacific\/Gambier",
    "offset":"UTC -09:00"
  },
  {
    "zone":"Pacific\/Guadalcanal",
    "offset":"UTC +11:00"
  },
  {
    "zone":"Pacific\/Guam",
    "offset":"UTC +10:00"
  },
  {
    "zone":"Pacific\/Honolulu",
    "offset":"UTC -10:00"
  },
  {
    "zone":"Pacific\/Johnston",
    "offset":"UTC -10:00"
  },
  {
    "zone":"Pacific\/Kiritimati",
    "offset":"UTC -10:40"
  },
  {
    "zone":"Pacific\/Kosrae",
    "offset":"UTC +12:00"
  },
  {
    "zone":"Pacific\/Kwajalein",
    "offset":"UTC -12:00"
  },
  {
    "zone":"Pacific\/Majuro",
    "offset":"UTC +12:00"
  },
  {
    "zone":"Pacific\/Marquesas",
    "offset":"UTC -09:30"
  },
  {
    "zone":"Pacific\/Midway",
    "offset":"UTC -11:00"
  },
  {
    "zone":"Pacific\/Nauru",
    "offset":"UTC +11:30"
  },
  {
    "zone":"Pacific\/Niue",
    "offset":"UTC -11:30"
  },
  {
    "zone":"Pacific\/Norfolk",
    "offset":"UTC +11:30"
  },
  {
    "zone":"Pacific\/Noumea",
    "offset":"UTC +11:00"
  },
  {
    "zone":"Pacific\/Pago_Pago",
    "offset":"UTC -11:00"
  },
  {
    "zone":"Pacific\/Palau",
    "offset":"UTC +09:00"
  },
  {
    "zone":"Pacific\/Pitcairn",
    "offset":"UTC -08:30"
  },
  {
    "zone":"Pacific\/Pohnpei",
    "offset":"UTC +11:00"
  },
  {
    "zone":"Pacific\/Port_Moresby",
    "offset":"UTC +10:00"
  },
  {
    "zone":"Pacific\/Rarotonga",
    "offset":"UTC -10:30"
  },
  {
    "zone":"Pacific\/Saipan",
    "offset":"UTC +10:00"
  },
  {
    "zone":"Pacific\/Tahiti",
    "offset":"UTC -10:00"
  },
  {
    "zone":"Pacific\/Tarawa",
    "offset":"UTC +12:00"
  },
  {
    "zone":"Pacific\/Tongatapu",
    "offset":"UTC +13:00"
  },
  {
    "zone":"Pacific\/Wake",
    "offset":"UTC +12:00"
  },
  {
    "zone":"Pacific\/Wallis",
    "offset":"UTC +12:00"
  },
  {
    "zone":"UTC",
    "offset":"UTC +00:00"
  }
];
