var React = require('react/addons');
var industries = require('../../../../js/data/industries');

var IndustrySelect = React.createClass({
  mixins: [React.addons.LinkedStateMixin],

  getDefaultProps: function() {
    return {
      industry: null
    };
  },

  getInitialState: function() {
    return {
      industry: this.props.industry
    };
  },

  render: function() {
    var industryOptions = [];

    industries.forEach(function(option, i) {
      var value = option.value ? option.value : option.label;
      industryOptions.push(<option value={value} key={'industry.' + i}>{option.label}</option>);
    });

    return (
      <select
        id={this.props.id}
        className="form-control"
        valueLink={this.linkState('industry')}
        required>
        <option value="">-- Please select --</option>
        {industryOptions}
      </select>
    );
  }
});

module.exports = IndustrySelect;
