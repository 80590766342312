var React = require('react');

var DatePicker = React.createClass({

  getDefaultProps: function() {
    return {
      stateRef: null,
      label: null,
      value: null,
      required: false,
      startDate: new Date(),
      initialClear: false
    };
  },

  getInitialState: function() {
    return {
      datePickerInitialized: false,
      label: this.props.label,
      value: this.props.value,
      required: this.props.required,
      initialClear: this.props.initialClear,
      initialCleared: false
    };
  },

  componentDidMount: function() {
    if (this.state.datePickerInitialized) {
      return;
    }
    this.setState({datePickerInitialized: true});

    $(this.refs.datePicker.getDOMNode()).datepicker({
      autoclose: true,
      orientation: 'top left',
      startDate: this.props.startDate
    });

    $(this.refs.datePicker.getDOMNode()).on('changeDate', this.handleDateChange);
    $(this.refs.datePicker.getDOMNode()).prop('required', this.props.required);
  },

  componentWillReceiveProps: function(nextProps) {
    this.setState({
      required: nextProps.required
    });

    if (nextProps.required) {
      $(this.refs.datePicker.getDOMNode()).prop('required', true);
    } else {
      $(this.refs.datePicker.getDOMNode()).prop('required', false);
      $(this.refs.formGroup.getDOMNode()).removeClass('has-error');
    }

    if (nextProps.startDate) {
      $(this.refs.datePicker.getDOMNode()).datepicker('setStartDate', nextProps.startDate);
    }

    // Move the date if the start date is greater than the currently selected date.
    if (nextProps.startDate && this.state.value) {
      var nextPropsStartDate = new Date(nextProps.startDate);
      var nextPropsStartMonth = nextPropsStartDate.getMonth();
      var nextPropsStartDay = nextPropsStartDate.getDate();
      var nextPropsStartYear = nextPropsStartDate.getFullYear();

      if (new Date(nextPropsStartMonth + '/' + nextPropsStartDay + '/' + nextPropsStartYear) > new Date(this.state.value)) {
        $(this.refs.datePicker.getDOMNode()).datepicker('setDate', nextProps.startDate);
      }
    }
  },

  componentDidUpdate: function() {

    if (this.props.value === null && this.state.initialClear && ! this.state.initialCleared) {
      this.setState({initialCleared: true});
      $(this.refs.datePicker.getDOMNode()).val('');
    }

  },

  componentWillUnmount: function() {
    $(this.refs.datePicker.getDOMNode()).off();
    $(this.refs.datePicker.getDOMNode()).datepicker('remove');
  },

  handleDateChange: function(e) {
    var value = this.refs.datePicker.getDOMNode().value;

    this.setState({value: value});

    // Call parent onChange handler if set.
    if (this.props.onChange) {
      this.props.onChange(this.props.stateRef, value);
    }
  },

  render: function() {
    return (
      <div ref="formGroup" className="form-group">
        <label htmlFor="datePicker" className="control-label">{this.state.label} {this.state.required ? '*' : ''}</label>
        <input
          id="datePicker"
          type="text"
          ref="datePicker"
          className="form-control"
          defaultValue={this.props.value} />
        <div className="help-block with-errors"></div>
      </div>
    );
  }
});

module.exports = DatePicker;
