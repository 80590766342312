var React = require('react');
var countries = require('../../../../js/data/countries');

var CountrySelect = React.createClass({

  getDefaultProps: function() {
    return {
      country: null
    };
  },

  onChange: function(e) {
    this.props.onChange(this.refs.countrySelect.getDOMNode().value);
  },

  render: function() {
    var countryOptions = [];

    countries.forEach(function(option, i) {
      countryOptions.push(<option value={option.value} key={'country.' + i}>{option.label}</option>);
    });

    return (
      <select
        ref="countrySelect"
        id={this.props.id}
        className="form-control"
        defaultValue={this.props.country}
        onChange={this.onChange}
        required>
        <option value="">-- Please select --</option>
        {countryOptions}
      </select>
    );
  }
});

module.exports = CountrySelect;
