var Reflux = require('reflux');
var SignupActions = require('../actions/SignupActions');

var errors = {};

var RegisterBuyerStore = Reflux.createStore({

  listenables: [SignupActions],

  getErrors: function() {
    return errors;
  },

  onRegisterBuyerCompleted: function (res) {
    errors = {};
    this.trigger('REGISTER_BUYER_COMPLETED');
  },

  onRegisterBuyerFailed: function (res) {
    if (typeof res.data.errors !== 'undefined') {
      errors = res.data.errors;
    }

    this.trigger('REGISTER_BUYER_FAILED');
  }

});

module.exports = RegisterBuyerStore;
