module.exports = [
  {value:'', label:'Mining, Quarrying, and Oil and Gas Extraction'},
  {value:'', label:'Utilities'},
  {value:'', label:'Construction'},
  {value:'', label:'Manufacturing'},
  {value:'', label:'Wholesale Trade'},
  {value:'', label:'Retail Trade'},
  {value:'', label:'Transportation and Warehousing'},
  {value:'', label:'Information and Cultural Industries'},
  {value:'', label:'Finance and Insurance'},
  {value:'', label:'Real Estate and Rental and Leasing'},
  {value:'', label:'Professional, Scientific and Technical Services'},
  {value:'', label:'Management of Companies and Enterprises'},
  {value:'', label:'Administrative and Support, Waste Management and Remediation Services'},
  {value:'', label:'Educational Services'},
  {value:'', label:'Health Care and Social Assistance'},
  {value:'', label:'Arts, Entertainment and Recreation'},
  {value:'', label:'Accommodation and Food Services'},
  {value:'', label:'Other Services'},
  {value:'', label:'Public Administration'}
];
