var Reflux = require('reflux');
var ServiceAreaAPIUtils = require('../utils/ServiceAreaAPIUtils');

var ServiceAreaActions = Reflux.createActions({
  'getServiceAreas': {asyncResult: true},
  'createServiceArea': {asyncResult: true},
  'getServiceArea': {asyncResult: true},
  'updateServiceArea': {asyncResult: true},
  'deleteServiceArea': {asyncResult: true},
  'getServiceAreaCheckboxes': {asyncResult: true}
});

ServiceAreaActions.getServiceAreas.listenAndPromise(ServiceAreaAPIUtils.getServiceAreas);
ServiceAreaActions.createServiceArea.listenAndPromise(ServiceAreaAPIUtils.createServiceArea);
ServiceAreaActions.getServiceArea.listenAndPromise(ServiceAreaAPIUtils.getServiceArea);
ServiceAreaActions.updateServiceArea.listenAndPromise(ServiceAreaAPIUtils.updateServiceArea);
ServiceAreaActions.deleteServiceArea.listenAndPromise(ServiceAreaAPIUtils.deleteServiceArea);
ServiceAreaActions.getServiceAreaCheckboxes.listenAndPromise(ServiceAreaAPIUtils.getServiceAreaCheckboxes);

module.exports = ServiceAreaActions;
