var React = require('react/addons');
var Reflux = require('reflux');
var Router = require('react-router');
var AccountActions = require('../actions/AccountActions');
var SendPasswordResetStore = require('../stores/SendPasswordResetStore');

var ForgotPassword = React.createClass({

  mixins: [React.addons.LinkedStateMixin, Reflux.ListenerMixin, Router.Navigation],

  getInitialState: function() {
    return {
      email: '',
      alert: '',
      completeMessage: false
    };
  },

  componentDidMount: function() {
    // Handle form submit
    $('#forgot-password-form').validator().on('submit', this.handleSendPasswordResetSubmit);

    // On send password reset
    this.listenTo(SendPasswordResetStore, this.onSendPasswordResetStore);
  },

  handleSendPasswordResetSubmit: function(e) {
    this.setState({alert: ''});

    if (! e.isDefaultPrevented()) {
      e.preventDefault();
      $('#forgot-password-form button').prop('disabled', true);
      AccountActions.sendPasswordReset(this.state.email);
    }
  },

  onSendPasswordResetStore: function(status) {
    if (status === 'SEND_PASSWORD_RESET_COMPLETED') {
      this.setState({completeMessage: true});
    } else if (status === 'SEND_PASSWORD_RESET_FAILED') {
      $('#forgot-password-form button').prop('disabled', false);

      this.setState({alert: 'Problem sending password reset email, please check your email and try again.'});
    }
  },

  componentDidUnmount: function() {
    $('#forgot-password-form').validator('destroy').off();
  },

  render: function() {
    var content;
    var alert;

    alert = this.state.alert ? (
      <div className="alert alert-danger" role="alert">
        <i className="fa fa-exclamation-circle"></i> <strong>Error!</strong> {this.state.alert}
      </div>
    ) : '';

    content = (
      <div>
        {alert}
        <form className="form-card" data-toggle="validator" id="forgot-password-form">
          <div className="form-group">
            <label htmlFor="inputEmail" className="control-label">Email</label>
            <input type="email" id="inputEmail" className="form-control" placeholder="Enter your email" required autofocus data-error="Please enter an email address" valueLink={this.linkState('email')} />
            <div className="help-block with-errors"></div>
          </div>

          <div className="form-group">
            <button className="btn btn-lg btn-primary btn-block" type="submit">Submit</button>
          </div>
        </form>
      </div>
    );

    if (this.state.completeMessage) {
      content = (<div className="alert alert-success" role="alert">
        <i className="fa fa-check-circle"></i> <strong>Password Reset Request Sent!</strong> A password reset request has been sent, please check your email and follow the instructions to reset your password.
      </div>);
    }

    return (
      <div className="container">

        <div className="text-center sigin-welcome">
          <a href="/"><img alt="AFEMax™" src="/img/logo.png" height="55" /></a>
          <h2>Forgot your password?</h2>
        </div>

        <div className="card card-container">
          <img className="profile-img-card" src="//ssl.gstatic.com/accounts/ui/avatar_2x.png" />
          {content}
        </div>
      </div>
    );
  }

});

module.exports = ForgotPassword;
