var Reflux = require('reflux');
var AccountActions = require('../actions/AccountActions');

var LoginStore = Reflux.createStore({

  listenables: [AccountActions],

  onLoginCompleted: function (res) {
    this.trigger('LOGIN_COMPLETED');
  },

  onLoginFailed: function (res) {
    this.trigger('LOGIN_FAILED');
  }

});

module.exports = LoginStore;
