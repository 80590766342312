var React = require('react');
var DatePicker = require('../../shared/DatePicker');
var StateProvSelect = require('../../shared/StateProvSelect');
var _ = require('lodash');

var WCBFieldGroup = React.createClass({
  getDefaultProps: function() {
    return {
      state: null,
      number: null,
      expire_at: null
    };
  },

  getInitialState: function() {
    return {
      state: this.props.initialWCB.state,
      number: this.props.initialWCB.number,
      expire_at: this.props.initialWCB.expire_at
    };
  },

  componentDidUpdate: function(nextProps) {
    // bugfix, clear datepicker if no date set
    if (! this.state.expire_at) {
      this.refs.expiryDate.refs.datePicker.getDOMNode().value = '';
    }
  },

  handleStateProvChange: function(coverageState) {
    this.setState({state: coverageState});
  },

  handleNumberChange: function() {
    this.setState({number: this.refs.number.getDOMNode().value});
  },

  handleExpiryChange: function(stateRef, value) {
    this.setState({[stateRef]: value});
  },

  handleRemoveClick: function() {
    if (this.props.onRemoveClick) {
      confirm2('Are you sure you want to remove this WCB?', () => {
        this.props.onRemoveClick(this.props.wcbId);
      });
    }
  },

  render: function() {
    return (
      <div className="row">
        <div className="col-sm-3 col-md-3">
          <div className="form-group">
            <label htmlFor="selectWCBStateProve" className="control-label">WCB Coverage State/Prov</label>
            <StateProvSelect
              id="selectWCBStateProve"
              defaultValue={this.state.state}
              onChange={this.handleStateProvChange} />
            <div className="help-block with-errors"></div>
          </div>
        </div>
        <div className="col-sm-3 col-md-3">
          <div className="form-group">
            <label htmlFor="inputWCBNumber" className="control-label">WCB Coverage Number {this.state.state ? '*' : ''}</label>
            <input
              ref="number"
              type="text"
              id="inputWCBNumber"
              className="form-control"
              required={this.state.state}
              defaultValue={this.state.number}
              onChange={this.handleNumberChange} />
            <div className="help-block with-errors"></div>
          </div>
        </div>
        <div className="col-sm-3 col-md-2">
          <DatePicker
            ref="expiryDate"
            label="Expiry date"
            required={this.state.state}
            value={this.state.expire_at}
            stateRef="expire_at"
            initialClear={true}
            onChange={this.handleExpiryChange} />
        </div>
        <div className="col-sm-3  col-md-4">
          <a className="hidden-xs" href="javascript:;" style={{display: 'inline-block', marginTop: '30px'}} onClick={this.handleRemoveClick}><i className="fa fa-minus-circle"></i> Remove</a>

          <a className="visible-xs" href="javascript:;" style={{display: 'inline-block', marginBottom: '20px', textAlign: 'right'}} onClick={this.handleRemoveClick}><i className="fa fa-minus-circle"></i> Remove</a>
        </div>
      </div>
    );
  }
});

var MultipleWCBs = React.createClass({
  mixins: [React.addons.LinkedStateMixin],

  getDefaultProps: function() {
    return {
      initialWCBs: []
    };
  },

  getInitialState: function() {
    var wcbs = [];
    var d = new Date();

    // Inialize with empty wcb if none provided
    if (this.props.initialWCBs && this.props.initialWCBs.length > 0) {
      wcbs = this.props.initialWCBs;
    } else {
      wcbs.push({
        id: d.getTime(),
        state: null,
        number: null,
        expire_at: null
      });
    }

    return {
      wcbs: wcbs
    };
  },

  handleAddFieldGroup: function() {
    var wcbs = this.state.wcbs;
    var d = new Date();
    var wcb = {
      id: d.getTime(),
      state: null,
      number: null,
      expire_at: null
    };

    wcbs.push(wcb);

    this.setState({
      wcbs: wcbs
    });
  },

  handleRemoveClick: function(id) {
    var wcbs = this.state.wcbs;

    var indexOfItemToRemove = _.findIndex(wcbs, function(wcb) { return wcb.id === id; });

    wcbs.splice(indexOfItemToRemove, 1);

    this.setState({
      wcbs: wcbs
    });
  },

  getWCBs: function() {
    var wcbs = [];

    this.state.wcbs.forEach(function(_wcb, index) {
      var wcb = this.refs[_wcb.id];

      if (wcb.state.state && wcb.state.number && wcb.state.expire_at) {
        wcbs.push({
          state: wcb.state.state,
          number: wcb.state.number,
          expire_at: wcb.state.expire_at
        });
      }
    }, this);

    return wcbs;
  },

  render: function() {
    var wcbFieldGroups = [];

    if (this.state.wcbs && this.state.wcbs.length) {
      this.state.wcbs.forEach(function(wcb, i) {
        wcbFieldGroups.push(<WCBFieldGroup key={wcb.id} ref={wcb.id} wcbId={wcb.id} initialWCB={wcb} onRemoveClick={this.handleRemoveClick} />);
      }, this);
    }

    return (
      <div>
        {wcbFieldGroups}

        <div className="row" style={{marginBottom: '20px'}}>
          <div className="col-sm-12">
            <a href="javascript:;" onClick={this.handleAddFieldGroup}>+ Add WCB</a>
          </div>
        </div>
      </div>
    );
  }
});

module.exports = MultipleWCBs;
