var Reflux = require('reflux');
var SignupActions = require('../actions/SignupActions');

var errors = {};

var RegisterSellerStore = Reflux.createStore({

  listenables: [SignupActions],

  getErrors: function() {
    return errors;
  },

  onRegisterSellerCompleted: function (res) {
    errors = {};
    this.trigger('REGISTER_SELLER_COMPLETED');
  },

  onRegisterSellerFailed: function (res) {
    if (typeof res.data.errors !== 'undefined') {
      errors = res.data.errors;
    }

    this.trigger('REGISTER_SELLER_FAILED');
  }

});

module.exports = RegisterSellerStore;
