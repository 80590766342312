var React = require('react');
var Navbar = require('../../infrastructure/components/Navbar');

var NotFound = React.createClass({
  render: function() {
    var sampleAuctionImageStyle = {
      display: 'inline-block'
    };

    return (
      <div>
        <Navbar />

        <div className="title-banner">
          <div className="container">
            <h1>Page Not Found</h1>
          </div>
        </div>

        <div className="container main-container">
          <div className="row">
            <div className="col-md-12" style={{textAlign: 'center'}}>
              <h2>Error 404</h2>
              <p>The page you are looking for does not exist.</p>
            </div>
          </div>
        </div>

      </div>
    );
  }
});

module.exports = NotFound;
