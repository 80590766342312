module.exports = [
  {value:'AB', label:'Alberta'},
  {value:'BC', label:'British Columbia'},
  {value:'MB', label:'Manitoba'},
  {value:'NB', label:'New Brunswick'},
  {value:'NL', label:'Newfoundland and Labrador'},
  {value:'NS', label:'Nova Scotia'},
  {value:'NU', label:'Nunavut'},
  {value:'NW', label:'Northwest Territories'},
  {value:'ON', label:'Ontario'},
  {value:'PE', label:'Prince Edward Island'},
  {value:'QC', label:'Quebec'},
  {value:'SK', label:'Saskatchewan'},
  {value:'YU', label:'Yukon'}
];
