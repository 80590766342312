var Reflux = require('reflux');
var AccountActions = require('../actions/AccountActions');

var errors = {};

var SetPasswordStore = Reflux.createStore({

  listenables: [AccountActions],

  getErrors: function() {
    return errors;
  },

  onSetPasswordCompleted: function (res) {
    errors = {};
    this.trigger('SET_PASSWORD_COMPLETED');
  },

  onSetPasswordFailed: function (res) {
    if (typeof res.data.errors !== 'undefined') {
      errors = res.data.errors;
    }

    this.trigger('SET_PASSWORD_FAILED');
  }

});

module.exports = SetPasswordStore;
