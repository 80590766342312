var React = require('react/addons');
var Reflux = require('reflux');
var Router = require('react-router');
var Navbar = require('../../infrastructure/components/Navbar');
var AccountActions = require('../actions/AccountActions');
var VerifyPasswordResetTokenStore = require('../stores/VerifyPasswordResetTokenStore');
var SetPasswordStore = require('../stores/SetPasswordStore');

var ResetPassword = React.createClass({

  mixins: [React.addons.LinkedStateMixin, Reflux.ListenerMixin, Router.Navigation],

  getInitialState: function() {
    return {
      newPassword: '',
      alert: '',
      systemAlert: '',
      completeMessage: false
    };
  },

  componentDidMount: function() {
    this.listenTo(VerifyPasswordResetTokenStore, this.onVerifyPasswordResetTokenStore);
    AccountActions.verifyPasswordResetToken(this.props.params.passwordResetToken);

    this.listenTo(SetPasswordStore, this.onSetPasswordStore);

    // Handle form submit
    $('#reset-password-form').validator().on('submit', this.handleResetPasswordSubmit);
  },

  componentWillUnmount: function() {
    $('#reset-password-form').validator('destroy').off();
  },

  onVerifyPasswordResetTokenStore: function(status) {
    if (status === 'VERIFY_PASSWORD_RESET_TOKEN_FAILED') {
      $('#account-setup-form button').prop('disabled', false);
      this.setState({systemAlert: 'Please check the link and try again.'});
    }
  },

  onSetPasswordStore: function(status) {
    if (status === 'SET_PASSWORD_COMPLETED') {
      this.setState({completeMessage: true});
    } else if (status === 'SET_PASSWORD_FAILED') {
      $('#account-setup-form button').prop('disabled', false);

      var errors = SetPasswordStore.getErrors();
      if (typeof errors.newPassword !== 'undefined') {
        this.setState({alert: errors.newPassword});
      } else {
        this.setState({alert: 'Problem setting your password, please try again.'});
      }
    }
  },

  handleResetPasswordSubmit: function(e) {
    this.setState({alert: ''});

    if (! e.isDefaultPrevented()) {
      e.preventDefault();
      $('#reset-password-form button').prop('disabled', true);
      AccountActions.setPassword(this.props.params.passwordResetToken, this.state.newPassword);
    }
  },

  render: function() {
    var content;
    var alert;

    alert = this.state.alert ? (
      <div className="alert alert-danger" role="alert">
        <i className="fa fa-exclamation-circle"></i> <strong>Error!</strong> {this.state.alert}
      </div>
    ) : '';

    content = (
      <div>
        {alert}
        <form className="form-card" id="reset-password-form">
          <div className="form-group">
            <label htmlFor="inputNewPassword" className="control-label">New Password *</label>
            <input type="password" id="inputNewPassword" className="form-control" required autofocus  valueLink={this.linkState('newPassword')} data-minlength="6" />
            <div className="help-block with-errors"></div>
          </div>

          <div className="form-group">
            <label htmlFor="inputConfirmPassword" className="control-label">Confirm Password * </label>
            <input type="password" id="inputConfirmPassword" className="form-control" required data-match="#inputNewPassword" data-match-error="Passwords do not match." />
            <div className="help-block with-errors"></div>
          </div>

          <button className="btn btn-lg btn-primary btn-block" type="submit">Submit</button>
        </form>
      </div>
    );

    if (this.state.completeMessage) {
      content = (<div className="alert alert-success" role="alert">
        <i className="fa fa-check-circle"></i> <strong>Password Reset!</strong> Login <a href="/login">here</a>.
      </div>);
    }

    if (this.state.systemAlert) {
      content = (<div className="alert alert-danger" role="alert">
        <i className="fa fa-exclamation-circle"></i> <strong>Error!</strong> {this.state.systemAlert}
      </div>)
    }

    return (
      <div className="container">

        <div className="text-center sigin-welcome">
          <a href="/"><img alt="AFEMax™" src="/img/logo.png" height="55" /></a>
          <h2>Reset your password</h2>
        </div>

        <div className="card card-container">
          {content}
        </div>
      </div>
    );
  }

});

module.exports = ResetPassword;
