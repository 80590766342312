var React = require('react/addons');
var Reflux = require('reflux');
var Router = require('react-router');
var Navbar = require('../../infrastructure/components/Navbar');
var SignupActions = require('../actions/SignupActions');
var RegistrationRequestStore = require('../stores/RegistrationRequestStore');

var RegistrationRequest = React.createClass({

  mixins: [React.addons.LinkedStateMixin, Reflux.ListenerMixin, Router.Navigation],

  getInitialState: function() {
    var orgType = '';
    
    if(typeof this.props.query.type !== "undefined") {
      if(this.props.query.type == "buyer") {
        orgType = 2;
      }
      if(this.props.query.type == "seller") {
        orgType = 3;
      }
      if(this.props.query.type == "consultant") {
        orgType = 4;
      }
    }
    
    return {
      name: '',
      organizationName: '',
      organizationType: orgType,
      mobileNumber: '',
      alert: ''
    };
  },

  componentDidMount: function() {
    this.listenTo(RegistrationRequestStore, this.onRegistrationRequestStore);

    // Handle form submit
    $('#request-code-form').validator().on('submit', this.handleRequestCodeSubmit);
  },

  componentWillUnmount: function() {
    $('#request-code-form').validator('destroy').off();
  },

  onRegistrationRequestStore: function(status) {
    if (status === 'REQUEST_CODE_COMPLETED') {
      var mobileNumber = RegistrationRequestStore.getMobileNumber();

      this.transitionTo('verify-access-code', {mobileNumber: mobileNumber});
    } else if (status === 'REQUEST_CODE_FAILED') {
      $('#request-code-form button').prop('disabled', false);

      var errors = RegistrationRequestStore.getErrors();

      if (typeof errors.mobileNumber !== 'undefined') {
        this.setState({alert: errors.mobileNumber});
      } else {
        this.setState({alert: 'Failed to send access code. Please check your mobile number and try again.'});
      }
    }
  },

  handleRequestCodeSubmit: function(e) {
    this.setState({alert: ''});

    if (! e.isDefaultPrevented()) {
      e.preventDefault();
      $('#request-code-form button').prop('disabled', true);
      SignupActions.requestCode(this.state.name, this.state.organizationName, this.state.organizationType, this.state.mobileNumber);
    }
  },

  render: function() {
    var alert = this.state.alert ? (
      <div className="alert alert-danger" role="alert">
        <i className="fa fa-exclamation-circle"></i> <strong>Error!</strong> {this.state.alert}
      </div>
    ) : '';
    
    return (
      <div>
        <Navbar />

        <div className="title-banner">
          <div className="container">
            <h1>Sign up</h1>
          </div>
        </div>

        <div className="container main-container">
          <div className="row">
            <div className="col-md-6">
              <h3>Registration Request</h3>
              <p>To verify that you are a real person, we require verification via text message (SMS). Please enter your information in the form below, and we will send you an instant message containing an access code. You can then enter the code on the following page to begin the registration process.</p>
            </div>

            <div className="col-md-6">
              <div className="card card-container">
                {alert}
                <form className="form-card" data-toggle="validator" id="request-code-form">

                  <div className="form-group">
                    <label htmlFor="inputName" className="control-label">Your Name</label>
                    <input type="text" id="inputName" className="form-control" placeholder="Your Name" valueLink={this.linkState('name')} required autofocus />
                    <div className="help-block with-errors"></div>
                  </div>

                  <div className="form-group">
                    <label htmlFor="inputOrganizationName" className="control-label">Organization Name</label>
                    <input type="text" id="inputOrganizationName" className="form-control" placeholder="Organization Name" valueLink={this.linkState('organizationName')} required />
                    <div className="help-block with-errors"></div>
                  </div>

                  <div className="form-group">
                    <label htmlFor="selectOrganizationType" className="control-label">Organization Type</label>
                    <select id="selectOrganizationType" className="form-control" valueLink={this.linkState('organizationType')} required>
                      <option value="">-- Please select --</option>
                      <option value="2">Buyer</option>
                      <option value="3">Seller/Service Company</option>
                      <option value="4">Consultant</option>
                    </select>
                    <div className="help-block with-errors"></div>
                  </div>

                  <div className="form-group">
                    <label htmlFor="inputMobileNumber" className="control-label">Mobile Number</label>
                    <input type="phone" pattern="^([\+]?\d{1,4})?[\.-]?[\(]?\d{3}[\)]?[\.-]?\d{3}[\.-]?\d{4}$" id="inputMobileNumber" className="form-control" placeholder="xxx-xxx-xxxx" valueLink={this.linkState('mobileNumber')} required />
                    <div className="help-block with-errors"></div>
                  </div>

                  <button className="btn btn-lg btn-primary btn-block" type="submit">Request Code</button>
                </form>
              </div>

            </div>
          </div>
        </div>

      </div>
    );
  }

});

module.exports = RegistrationRequest;
