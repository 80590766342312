var React = require('react');

var Navbar = React.createClass({
  render: function() {
    return (
      <div>
        <nav className="navbar navbar-custom navbar-fixed-top">
          <div className="container">
            <div className="navbar-header">
              <a className="btn btn-secondary navbar-toggle collapsed" data-toggle="collapse" data-target="#navbar" aria-expanded="false" aria-controls="navbar">
                <span className="sr-only">Toggle navigation</span>
                SITE MENU
              </a>
              <a className="navbar-brand" href="/">
                <img alt="AFEMax™" src="/img/logo.png" />
              </a>
            </div>
            <div id="navbar" className="collapse navbar-collapse">
              <ul className="nav navbar-nav">
                <li className="active"><a href="/">Home</a></li>
                <li><a href="/about">About</a></li>
                <li><a href="/faq">FAQ</a></li>
              </ul>

              <div className="pull-right">
                <a href="/login" className="btn btn-secondary">Login</a>
                <a href="/signup" className="btn btn-primary">Sign up</a>
              </div>
            </div>
          </div>
        </nav>
      </div>
    );
  }
});

module.exports = Navbar;
