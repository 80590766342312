var Reflux = require('reflux');
var StoreErrorMixin = require('../mixins/StoreErrorMixin');
var ServiceAreaActions = require('../actions/ServiceAreaActions');

var serviceAreas = [];
var serviceArea = null;
var serviceAreaCheckboxes = [];
var serviceAreaProvinceCheckboxes = [];

var ServiceAreaStore = Reflux.createStore({

  listenables: [ServiceAreaActions],

  mixins: [StoreErrorMixin],

  getServiceAreas: function() {
    return serviceAreas;
  },

  getServiceArea: function() {
    return serviceArea;
  },

  // Collection
  onGetServiceAreasCompleted: function(res) {
    this.clearErrors();
    serviceAreas = res.data;
    this.trigger('GET_SERVICE_AREAS_COMPLETED');
  },

  onGetServiceAreasFailed: function(res) {
    this.setErrors(res);
    this.trigger('GET_SERVICE_AREAS_FAILED');
  },

  // Model
  onCreateServiceAreaCompleted: function(res) {
    this.clearErrors();
    this.trigger('CREATE_SERVICE_AREA_COMPLETED');
  },

  onCreateServiceAreaFailed: function(res) {
    this.setErrors(res);
    this.trigger('CREATE_SERVICE_AREA_FAILED');
  },

  onGetServiceAreaCompleted: function(res) {
    this.clearErrors();
    serviceArea = res.data;
    this.trigger('GET_SERVICE_AREA_COMPLETED');
  },

  onGetServiceAreaFailed: function(res) {
    this.setErrors(res);
    this.trigger('GET_SERVICE_AREA_FAILED');
  },

  onUpdateServiceAreaCompleted: function(res) {
    this.clearErrors();
    this.trigger('UPDATE_SERVICE_AREA_COMPLETED');
  },

  onUpdateServiceAreaFailed: function(res) {
    this.setErrors(res);
    this.trigger('UPDATE_SERVICE_AREA_FAILED');
  },

  onDeleteServiceAreaCompleted: function(res) {
    this.clearErrors();
    this.trigger('DELETE_SERVICE_AREA_COMPLETED');
  },

  onDeleteServiceAreaFailed: function(res) {
    this.setErrors(res);
    this.trigger('DELETE_SERVICE_AREA_FAILED');
  },

  // Checkboxes
  getServiceAreaCheckboxes: function() {
    return serviceAreaCheckboxes;
  },

  getServiceAreaProvinceCheckboxes: function() {
    return serviceAreaProvinceCheckboxes;
  },

  onGetServiceAreaCheckboxesCompleted: function(res) {
    this.clearErrors();
    serviceAreaCheckboxes = res.data.serviceAreas;
    serviceAreaProvinceCheckboxes = res.data.serviceAreaProvinces;
    this.trigger('GET_SERVICE_AREA_CHECKBOXES_COMPLETED');
  },

  onGetServiceAreaCheckboxesFailed: function(res) {
    this.setErrors(res);
    this.trigger('GET_SERVICE_AREA_CHECKBOXES_FAILED');
  },

});

module.exports = ServiceAreaStore;
