var React = require('react/addons');
var Reflux = require('reflux');
var Router = require('react-router');
var Navbar = require('../../infrastructure/components/Navbar');
var AccountActions = require('../actions/AccountActions');
var VerifyPasswordResetTokenStore = require('../stores/VerifyPasswordResetTokenStore');
var AccountSetupStore = require('../stores/AccountSetupStore');

var TimezoneSelect = require('../../../account/js/components/shared/TimezoneSelect');

var AccountSetup = React.createClass({

  mixins: [React.addons.LinkedStateMixin, Reflux.ListenerMixin, Router.Navigation],

  getInitialState: function() {
    return {
      newPassword: '',
      alert: '',
      systemAlert: '',
      completeMessage: false
    };
  },

  componentDidMount: function() {
    this.listenTo(VerifyPasswordResetTokenStore, this.onVerifyPasswordResetTokenStore);
    AccountActions.verifyPasswordResetToken(this.props.params.passwordResetToken);

    this.listenTo(AccountSetupStore, this.onAccountSetupStore);

    // Handle form submit
    $('#account-setup-form').validator().on('submit', this.handleResetPasswordSubmit);
  },

  componentWillUnmount: function() {
    $('#account-setup-form').validator('destroy').off();
  },

  onVerifyPasswordResetTokenStore: function(status) {
    if (status === 'VERIFY_PASSWORD_RESET_TOKEN_FAILED') {
      $('#account-setup-form button').prop('disabled', false);
      this.setState({systemAlert: 'Please check the link and try again.'});
    }
  },

  onAccountSetupStore: function(status) {
    if (status === 'SETUP_ACCOUNT_COMPLETED') {
      this.setState({completeMessage: true});
    } else if (status === 'SETUP_ACCOUNT_FAILED') {
      $('#account-setup-form button').prop('disabled', false);

      var errors = SetPasswordStore.getErrors();
      if (typeof errors.newPassword !== 'undefined') {
        this.setState({alert: errors.newPassword});
      } else {
        this.setState({alert: 'Problem setting your password, please try again.'});
      }
    }
  },

  handleResetPasswordSubmit: function(e) {
    this.setState({alert: ''});

    if (! e.isDefaultPrevented()) {
      e.preventDefault();
      $('#account-setup-form button').prop('disabled', true);
      AccountActions.setupAccount(this.props.params.passwordResetToken, this.state.newPassword, this.refs.timezone.state.value);
    }
  },

  render: function() {
    var content;

    var alert = this.state.alert ? (
      <div className="alert alert-danger" role="alert">
        <i className="fa fa-exclamation-circle"></i> <strong>Error!</strong> {this.state.alert}
      </div>
    ) : '';

    content = (
      <div className="row">
        <div className="col-md-6">
          <h3>Account Setup</h3>
          <p>To complete your registration please set your account password and timezone.</p>
        </div>

        <div className="col-md-6">
          <div className="card card-container">
            {alert}
            <form className="form-card" id="account-setup-form">

              <div className="form-group">
                <label htmlFor="inputNewPassword" className="control-label">New Password *</label>
                <input type="password" id="inputNewPassword" className="form-control" required autofocus  valueLink={this.linkState('newPassword')} data-minlength="6" />
                <div className="help-block with-errors"></div>
              </div>

              <div className="form-group">
                <label htmlFor="inputConfirmPassword" className="control-label">Confirm Password * </label>
                <input type="password" id="inputConfirmPassword" className="form-control" required data-match="#inputNewPassword" data-match-error="Passwords do not match." />
                <div className="help-block with-errors"></div>
              </div>

              <TimezoneSelect
                ref="timezone"
                required={true} />

              <button className="btn btn-lg btn-primary btn-block" type="submit">Submit</button>
            </form>
          </div>

        </div>
      </div>
    );

    if (this.state.completeMessage) {
      content = (<div className="alert alert-success" role="alert">
        <i className="fa fa-check-circle"></i> <strong>Setup Complete!</strong> Your account is now setup. Login <a href="/login">here</a>.
      </div>)
    }

    if (this.state.systemAlert) {
      content = (<div className="alert alert-danger" role="alert">
        <i className="fa fa-exclamation-circle"></i> <strong>Error!</strong> {this.state.systemAlert}
      </div>)
    }

    return (
      <div>
        <Navbar />

        <div className="title-banner">
          <div className="container">
            <h1>Account Setup</h1>
          </div>
        </div>

        <div className="container main-container">
          {content}
        </div>

      </div>
    );
  }

});

module.exports = AccountSetup;
