var React = require('react/addons');
var Reflux = require('reflux');
var Router = require('react-router');
var Navbar = require('../../infrastructure/components/Navbar');
var SignupActions = require('../actions/SignupActions');
var VerifyAccessTokenStore = require('../stores/VerifyAccessTokenStore');
var RegisterSellerStore = require('../stores/RegisterSellerStore');

var General = require('../../../account/js/components/organizations/form/General');
var SafetyProgram = require('../../../account/js/components/organizations/form/SafetyProgram');
var SafetyCertifications = require('../../../account/js/components/organizations/form/SafetyCertifications');
var Insurance = require('../../../account/js/components/organizations/form/Insurance');

var IndustrySelect = require('../../../account/js/components/shared/IndustrySelect');

var ServiceAreaActions = require('../../../account/js/actions/ServiceAreaActions');
var ServiceAreaStore = require('../../../account/js/stores/ServiceAreaStore');
var ServiceAreaCheckboxes = require('../../../account/js/components/shared/ServiceAreaCheckboxes');

var ServiceActions = require('../../../account/js/actions/ServiceActions');
var ServiceStore = require('../../../account/js/stores/ServiceStore');
var ServiceCheckboxes = require('../../../account/js/components/shared/ServiceCheckboxes');

// Key Personnel
var WorkExperience = require('./form/WorkExperience');
var Certifications = require('./form/Certifications');
var TechnicalTrainingAndEducation = require('./form/TechnicalTrainingAndEducation');

var SellerRegistration = React.createClass({

  mixins: [React.addons.LinkedStateMixin, Reflux.ListenerMixin, Router.Navigation],

  getInitialState: function() {
    return {
      alertDanger: null,
      alertSuccess: null,
      alertSelectServiceCategory: null,
      hideForm: false,
      validatorOn: false,
      paymentErrorMessage: '',
      addOperationsContact: false,

      serviceAreaCheckboxes: null,
      serviceAreaProvinceCheckboxes: null,
      serviceCheckboxes: null,

      stripe_payment_token: null,
      feedback: ''
    };
  },

  componentWillMount: function() {
    // Verify access token
    this.listenTo(VerifyAccessTokenStore, this.onVerifyAccessTokenStore);
    SignupActions.verifyAccessToken(this.props.params.accessToken);

    // Get service areas
    this.listenTo(ServiceAreaStore, this.onServiceAreaStore);
    ServiceAreaActions.getServiceAreaCheckboxes();

    // Get services
    this.listenTo(ServiceStore, this.onServiceStore);
    ServiceActions.getServiceCheckboxes();

    // Handle registration
    this.listenTo(RegisterSellerStore, this.onRegisterSellerStore);
  },

  componentDidUpdate: function() {
    if (! this.state.validatorOn && this.state.serviceAreaCheckboxes && this.state.serviceCheckboxes) {
      this.setState({
        validatorOn: true
      });

      $('#registration-form').validator().on('submit', this.handleRegistrationSubmit);
    }
  },

  componentWillUnmount: function() {
    $('#registration-form').validator('destroy').off();
  },

  onVerifyAccessTokenStore: function(status) {
    if (status === 'VERIFY_ACCESS_TOKEN_FAILED') {
      this.setState({
        alertDanger: 'Invalid access token.',
        hideForm: true
      });
    }
  },

  onServiceAreaStore: function(status) {
    if (status === 'GET_SERVICE_AREA_CHECKBOXES_COMPLETED') {
      this.setState({
        serviceAreaCheckboxes: ServiceAreaStore.getServiceAreaCheckboxes(),
        serviceAreaProvinceCheckboxes: ServiceAreaStore.getServiceAreaProvinceCheckboxes()
      });
    } else if (status === 'GET_SERVICE_AREA_CHECKBOXES_FAILED') {
      this.setState({alertDanger: 'Service areas unavailable.'});
    }
  },

  onServiceStore: function(status) {
    if (status === 'GET_SERVICE_CHECKBOXES_COMPLETED') {
      this.setState({
        serviceCheckboxes: ServiceStore.getServiceCheckboxes(),
      });
    } else if (status === 'GET_SERVICE_CHECKBOXES_FAILED') {
      this.setState({alertDanger: 'Services unavailable.'});
    }
  },

  handleAddOperationsContact: function(e) {
    this.setState({addOperationsContact: true});
  },

  handleRegistrationSubmit: function(e) {
    var selectedServices = this.refs.services.getFormData().length > 0;

    this.setState({
      alertDanger: null,
      alertSuccess: null,
      alertSelectServiceCategory: null
    });

    if (! e.isDefaultPrevented() && selectedServices) {
      e.preventDefault();
      $('.btn-submit').prop('disabled', true);
      $('.processing').show();
      Stripe.card.createToken($('#registration-form'), this.stripeResponseHandler);
    } else {

      /*/ Focus first error field
      if ($('.has-error:first input').length) {
        $('.has-error:first input').focus();
      } else if ($('.has-error:first select').length) {
        $('.has-error:first select').focus();
      } else if ($('.has-error:first textarea').length) {
        $('.has-error:first textarea').focus();
      }*/

      e.preventDefault();
      $('.btn-submit').prop('disabled', false);

      this.setState({
        alertDanger: 'Please ensure all required fields are filled in.'
      });

      if (! selectedServices) {
        this.setState({
          alertSelectServiceCategory: 'Please select at least one service category.'
        });
      }

      window.scrollTo(0,0);
    }
  },

  stripeResponseHandler: function(status, res) {
    if (status === 200) {

      var optionalOperationsContact = null;

      if (this.state.addOperationsContact) {
        optionalOperationsContact = {
          first_name: this.state.operations_contact_first_name,
          last_name: this.state.operations_contact_last_name,
          phone: this.state.operations_contact_phone,
          email: this.state.operations_contact_email,
          experience: this.state.operations_contact_experience,
          skills: this.state.operations_contact_skills,
          workExperiences: this.refs.operationsContactWorkExperience.getFormData(),
          certifications: this.refs.operationsContactCertifications.getFormData(),
          education: this.refs.operationsContactTechnicalTrainingAndEducation.getFormData()
        };
      }

      var organizationData = {
        reg_access_token: this.props.params.accessToken,

        name: this.refs.general.state.name,
        address: this.refs.general.state.address,
        city: this.refs.general.state.city,
        state: this.refs.general.state.state,
        country: this.refs.general.state.country,
        postal_zip: this.refs.general.state.postal_zip,
        phone: this.refs.general.state.phone,
        fax: this.refs.general.state.fax,
        email: this.refs.general.state.email,

        primary_industry: this.refs.primaryIndustry.state.industry,

        sa_written_safety_program: this.refs.safetyProgram.state.sa_written_safety_program,
        sa_conduct_hazard_assessments: this.refs.safetyProgram.state.sa_conduct_hazard_assessments,
        sa_safe_work_procedures: this.refs.safetyProgram.state.sa_safe_work_procedures,
        sa_one_man_op: this.refs.safetyProgram.state.sa_one_man_op,
        sa_third_party_sub: this.refs.safetyProgram.state.sa_third_party_sub,

        sc_comply_works_expiry: this.refs.safetyCertifications.state.sc_comply_works ? this.refs.safetyCertifications.state.sc_comply_works_expiry : null,
        sc_isnet_world_expiry: this.refs.safetyCertifications.state.sc_isnet_world ? this.refs.safetyCertifications.state.sc_isnet_world_expiry : null,
        sc_secor_expiry: this.refs.safetyCertifications.state.sc_secor ? this.refs.safetyCertifications.state.sc_secor_expiry : null,

        in_commercial_general: this.refs.insurance.state.in_commercial_general,
        in_commercial_general_expiry: this.refs.insurance.state.in_commercial_general_expiry,
        in_third_party_automobile: this.refs.insurance.state.in_third_party_automobile,
        in_third_party_automobile_expiry: this.refs.insurance.state.in_third_party_automobile_expiry,
        in_aircraft: this.refs.insurance.state.in_aircraft,
        in_aircraft_expiry: this.refs.insurance.state.in_aircraft_expiry,
        in_pollution: this.refs.insurance.state.in_pollution,
        in_pollution_expiry: this.refs.insurance.state.in_pollution_expiry,
        in_fire: this.refs.insurance.state.in_fire,
        in_fire_expiry: this.refs.insurance.state.in_fire_expiry,
        in_errors_omissions: this.refs.insurance.state.in_errors_omissions,
        in_errors_omissions_expiry: this.refs.insurance.state.in_errors_omissions_expiry,

        wcbs: this.refs.insurance.refs.multipleWCBs.getWCBs(),

        serviceAreas: this.refs.serviceAreas.getFormData(),
        services: this.refs.services.getFormData(),

        salesContact: {
          first_name: this.state.sales_contact_first_name,
          last_name: this.state.sales_contact_last_name,
          phone: this.state.sales_contact_phone,
          email: this.state.sales_contact_email,
          experience: this.state.sales_contact_experience,
          skills: this.state.sales_contact_skills,
          workExperiences: this.refs.salesContactWorkExperience.getFormData(),
          certifications: this.refs.salesContactCertifications.getFormData(),
          education: this.refs.salesContactTechnicalTrainingAndEducation.getFormData()
        },

        operationsContact: optionalOperationsContact,

        feedback: this.state.feedback,

        stripe_payment_token: res.id
      };

      SignupActions.registerSeller(organizationData);
    } else {
      this.setState({paymentErrorMessage: res.error.message});
      $('.btn-submit').prop('disabled', false);
      $('.processing').hide();
      $('#inputCardNumber').focus();
    }
  },

  onRegisterSellerStore: function(status) {
    if (status === 'REGISTER_SELLER_COMPLETED') {
      this.transitionTo('seller-registration-complete');
    } else if (status === 'REGISTER_SELLER_FAILED') {
      $('.btn-submit').prop('disabled', false);
      $('.processing').hide();
      window.scrollTo(0,0);

      var errors = RegisterSellerStore.getErrors();

      if (typeof errors.registration !== 'undefined') {
        this.setState({alertDanger: errors.registration});
      } else {
        this.setState({alertDanger: 'Registration could not be processed. Please contact us for assistance.'});
      }
    }
  },

  render: function() {
    var alertDanger;
    var alertSuccess;
    var alertSelectServiceCategory;
    var paymentErrorMessage;
    var form;
    var plan;

    alertDanger = this.state.alertDanger ? (
      <div className="alert alert-danger" role="alert">
        <i className="fa fa-exclamation-circle"></i> <strong>Error!</strong> {this.state.alertDanger}
      </div>
    ) : '';

    alertSuccess = this.state.alertSuccess ? (
      <div className="alert alert-success" role="alert">
        <i className="fa fa-check-circle"></i> {this.state.alertSuccess}
      </div>
    ) : '';

    alertSelectServiceCategory = this.state.alertSelectServiceCategory ? (
      <div className="alert alert-danger" role="alert">
        <i className="fa fa-exclamation-circle"></i> <strong>Error!</strong> {this.state.alertSelectServiceCategory}
      </div>
    ) : '';

    paymentErrorMessage = this.state.paymentErrorMessage ? (
      <div className="alert alert-danger" role="alert">
        <i className="fa fa-exclamation-circle"></i> <strong>Error!</strong> {this.state.paymentErrorMessage}
      </div>
    ) : '';

    // Operations Contact
    var operationsContact;

    if (this.state.addOperationsContact) {
      operationsContact = (
        <div className="card"
          style={{borderTop: '1px solid #E8E8E8', marginTop: '10px', backgroundColor: '#fff'}}>

          <h3 style={{marginBottom: '30px', marginTop: '10px'}}>Operations Contact</h3>

          <div className="row">
            <div className="col-sm-4">
              <div className="form-group">
                <label htmlFor="inputOperationsContactFirstName" className="control-label">First name</label>
                <input type="text" id="inputOperationsContactFirstName" className="form-control" placeholder="First name" valueLink={this.linkState('operations_contact_first_name')} />
                <div className="help-block with-errors"></div>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="form-group">
                <label htmlFor="inputOperationsContactLastName" className="control-label">First last</label>
                <input type="text" id="inputOperationsContactLastName" className="form-control" placeholder="Last name" valueLink={this.linkState('operations_contact_last_name')} />
                <div className="help-block with-errors"></div>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="form-group">
                <label htmlFor="inputOperationsContactPhone" className="control-label">Phone</label>
                <input type="tel" id="inputOperationsContactPhone" className="form-control" placeholder="Phone" valueLink={this.linkState('operations_contact_phone')} />
                <div className="help-block with-errors"></div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-4">
              <div className="form-group">
                <label htmlFor="inputOperationsContactEmail" className="control-label">Email</label>
                <input type="email" id="inputOperationsContactEmail" className="form-control" placeholder="Email" valueLink={this.linkState('operations_contact_email')} />
                <div className="help-block with-errors"></div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12">
              <div className="form-group">
                <label htmlFor="inputOperationsContactSummaryExperience" className="control-label">Summary of Experience</label>
                <textarea id="inputOperationsContactSummaryExperience" className="form-control" valueLink={this.linkState('operations_contact_experience')} />
                <div className="help-block with-errors"></div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12">
              <div className="form-group">
                <label htmlFor="inputOperationsContactSummarySkills" className="control-label">Summary of Skills</label>
                <textarea id="inputOperationsContactSummarySkills" className="form-control" valueLink={this.linkState('operations_contact_skills')} />
                <div className="help-block with-errors"></div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12">
              <label>Work Experience</label>

              <WorkExperience
                ref="operationsContactWorkExperience"
                identifier="operationsContact"/>

            </div>
          </div>

          <div className="row">
            <div className="col-sm-12">
              <label>Certifications</label>

              <Certifications
                ref="operationsContactCertifications"
                identifier="operationsContact"/>

            </div>
          </div>

          <div className="row">
            <div className="col-sm-12">
              <label>Technical Training &amp; Education</label>

              <TechnicalTrainingAndEducation
                ref="operationsContactTechnicalTrainingAndEducation"
                identifier="operationsContact"/>

            </div>
          </div>
        </div>);
    } else {
      operationsContact = (<a href="javascript:;" onClick={this.handleAddOperationsContact}> + Add Operations Contact</a>);
    }

    if (this.state.serviceAreaCheckboxes && this.state.serviceCheckboxes && ! this.state.hideForm) {
      form = (
        <form id="registration-form" className="card">

          <h3>1.0 General</h3>

          <General
            ref="general"/>

          <h3>2.0 Safety Program Information</h3>

          <div className="alert alert-info" role="alert">
            <strong>Note:</strong> We recommend this section be filled in as preference may be given to sellers with completed information.
          </div>
          <SafetyProgram
            ref="safetyProgram"/>

          <h3>3.0 Safety / Certifications</h3>

          <div className="alert alert-info" role="alert">
            <strong>Note:</strong> We recommend this section be filled in as preference may be given to sellers with completed information.
          </div>
          <SafetyCertifications
            ref="safetyCertifications"/>

          <h3>4.0 Insurance &amp; WCB</h3>

          <div className="alert alert-info" role="alert">
            <strong>Note:</strong> We recommend this section be filled in as preference may be given to sellers with completed information.
          </div>
          <Insurance
            ref="insurance"/>
          <div className="alert alert-info" role="alert">
            <strong>Note:</strong> If your company conducts work on an a buyer's worksite, the buyer must be named as an additional insured, not a certificate holder, on all policies. 
          </div>

          <h3>5.0 Primary Industry</h3>

          <div className="row">
            <div className="col-md-4">
              <div className="form-group">
                <label htmlFor="selectPrimaryIndustry" className="control-label">Primary Industry *</label>
                <IndustrySelect id="selectPrimaryIndustry" ref="primaryIndustry" />
                <div className="help-block with-errors"></div>
              </div>
            </div>
          </div>

          <h3>6.0 Service Areas</h3>

          <ServiceAreaCheckboxes
            ref="serviceAreas"
            serviceAreaCheckboxes={this.state.serviceAreaCheckboxes}
            serviceAreaProvinceCheckboxes={this.state.serviceAreaProvinceCheckboxes} />

          <h3>7.0 Service Categories</h3>
          {alertSelectServiceCategory}

          <ServiceCheckboxes
            ref="services"
            serviceCheckboxes={this.state.serviceCheckboxes} />

          <h3>8.0 Key Personnel</h3>

          <div className="row">
            <div className="col-md-12">
              <div className="alert alert-info" role="alert">
                <strong>Note:</strong> An account will be created based off of your sales contact.
              </div>

              <div className="card"
                style={{borderTop: '1px solid #E8E8E8', marginTop: '10px', backgroundColor: '#fff'}}>

                <h3 style={{marginBottom: '30px', marginTop: '10px'}}>Sales Contact</h3>

                <div className="row">
                  <div className="col-sm-4">
                    <div className="form-group">
                      <label htmlFor="inputSalesContactFirstName" className="control-label">First name *</label>
                      <input type="text" id="inputSalesContactFirstName" className="form-control" placeholder="First name" valueLink={this.linkState('sales_contact_first_name')} required />
                      <div className="help-block with-errors"></div>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="form-group">
                      <label htmlFor="inputSalesContactLastName" className="control-label">First last *</label>
                      <input type="text" id="inputSalesContactLastName" className="form-control" placeholder="Last name" valueLink={this.linkState('sales_contact_last_name')} required />
                      <div className="help-block with-errors"></div>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="form-group">
                      <label htmlFor="inputSalesContactPhone" className="control-label">Phone *</label>
                      <input type="tel" id="inputSalesContactPhone" className="form-control" placeholder="Phone" valueLink={this.linkState('sales_contact_phone')} required />
                      <div className="help-block with-errors"></div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-4">
                    <div className="form-group">
                      <label htmlFor="inputSalesContactEmail" className="control-label">Email *</label>
                      <input type="email" id="inputSalesContactEmail" className="form-control" placeholder="Email" valueLink={this.linkState('sales_contact_email')} required />
                      <div className="help-block with-errors"></div>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="form-group">
                      <label htmlFor="inputSalesContactEmailConfirm" className="control-label">Confirm Email *</label>
                      <input type="email" id="inputSalesContactEmailConfirm" className="form-control" placeholder="Confirm Email" required data-match="#inputSalesContactEmail" data-match-error="Emails don't match." />
                      <div className="help-block with-errors"></div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-12">
                    <div className="form-group">
                      <label htmlFor="inputSalesContactSummaryExperience" className="control-label">Summary of Experience *</label>
                      <textarea id="inputSalesContactSummaryExperience" className="form-control" valueLink={this.linkState('sales_contact_experience')} required />
                      <div className="help-block with-errors"></div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-12">
                    <div className="form-group">
                      <label htmlFor="inputSalesContactSummarySkills" className="control-label">Summary of Skills *</label>
                      <textarea id="inputSalesContactSummarySkills" className="form-control" valueLink={this.linkState('sales_contact_skills')} required />
                      <div className="help-block with-errors"></div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-12">
                    <label>Work Experience</label>

                    <WorkExperience
                      ref="salesContactWorkExperience"
                      identifier="salesContact"/>

                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-12">
                    <label>Certifications</label>

                    <Certifications
                      ref="salesContactCertifications"
                      identifier="salesContact"/>

                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-12">
                    <label>Technical Training &amp; Education</label>

                    <TechnicalTrainingAndEducation
                      ref="salesContactTechnicalTrainingAndEducation"
                      identifier="salesContact"/>

                  </div>
                </div>
              </div>

              {operationsContact}
            </div>
          </div>

          <h3>9.0 Feedback</h3>

          <div className="row">
            <div className="col-sm-12">
              <div className="form-group">
                <label htmlFor="textareaFeedback" className="control-label">Comments / Feedback</label>
                <textarea id="textareaFeedback" className="form-control" valueLink={this.linkState('feedback')}name="feedback" />
                <div className="help-block with-errors"></div>
              </div>
            </div>
          </div>

          <h3>10.0 Payment</h3>

          <div className="row">
            <div className="col-sm-6">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th colSpan="2">AFEMax™ Signup</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>AFEMax™ 7 Day Free Trial</td>
                    <td style={{textAlign: 'right', width: '20%'}}>Free</td>
                  </tr>
                  <tr>
                    <td>AFEMax™ Yearly Subscription <sup>&dagger;</sup></td>
                    <td style={{textAlign: 'right', width: '20%'}}>$ 200.00</td>
                  </tr>
                  <tr>
                    <td style={{textAlign: 'right'}}>Processing Fee</td>
                    <td style={{textAlign: 'right'}}>$ 7.00</td>
                  </tr>
                  <tr>
                    <td style={{textAlign: 'right'}}>GST</td>
                    <td style={{textAlign: 'right'}}>$ 10.35</td>
                  </tr>
                  <tr>
                    <td style={{textAlign: 'right'}}><strong>Total</strong></td>
                    <td style={{textAlign: 'right'}}><strong>$ 217.35</strong></td>
                  </tr>
                </tbody>
              </table>

              <p><sup>&dagger;</sup> The non-refundable subscription fee will come into effect after your 7 day free trial.</p>
            </div>

            <div className="col-sm-6">
              <div className="card"
                style={{borderTop: '1px solid #E8E8E8', marginTop: '0px', backgroundColor: '#fff'}}>

                <span className="payment-errors">{paymentErrorMessage}</span>

                <div className="row">
                  <div className="col-sm-12">
                    <div className="form-group">
                      <label htmlFor="inputCardNumber">Card number *</label>
                      <span className="pull-right"><i className="fa fa-cc-visa"></i> <i className="fa fa-cc-mastercard"></i> <i className="fa fa-cc-amex"></i></span>
                      <input id="inputCardNumber" className="form-control" type="text" size="20" data-stripe="number" placeholder="Card number" required />
                      <div className="help-block with-errors"></div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-4">
                    <div className="form-group">
                      <label htmlFor="inputCardMonth">Month (MM) *</label>
                      <input id="inputCardMonth" className="form-control" type="text" size="2" data-stripe="exp-month" placeholder="MM" required />
                      <div className="help-block with-errors"></div>
                    </div>
                  </div>

                  <div className="col-sm-4">
                    <div className="form-group">
                      <label htmlFor="inputCardYear">Year (YY) *</label>
                      <input id="inputCardYear" className="form-control" type="text" size="4" data-stripe="exp-year" placeholder="YY" required />
                      <div className="help-block with-errors"></div>
                    </div>
                  </div>

                  <div className="col-sm-4">
                    <div className="form-group">
                      <label htmlFor="inputCardCvc">CVC *</label>
                      <input id="inputCardCvc" className="form-control" type="text" size="4" data-stripe="cvc" placeholder="CVC" required />
                      <div className="help-block with-errors"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row" style={{marginTop: '20px'}}>
            <div className="col-sm-12">
              <button className="btn btn-primary btn-submit" type="submit">Submit</button>
              <span className="processing" style={{display: 'none', marginLeft: '10px', fontWeight: 'bold', fontColor: '#6a8c3b'}}>Processing <i className="fa fa-circle-o-notch fa-spin"></i></span>
            </div>
          </div>
        </form>);
    }

    return (
      <div>
        <Navbar />

        <div className="title-banner">
          <div className="container">
            <h1>Sign up</h1>
          </div>
        </div>

        <div className="container main-container">
          <div className="row">
            <div className="col-md-12">
              <h3>Seller Registration</h3>
              {alertDanger}
              {alertSuccess}
              {form}
            </div>
          </div>
        </div>

      </div>
    );
  }

});

module.exports = SellerRegistration;
