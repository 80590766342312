var React = require('react/addons');

var ServiceAreaCheckboxes = React.createClass({

  getDefaultProps: function() {
    return {
      checked: [],
      serviceAreaCheckboxes: [],
      serviceAreaProvinceCheckboxes: []
    };
  },

  componentDidMount: function() {
    // Initialize service area checkbox handlers
    $('input[name="serviceAreaCheckAll"]').off();
    $('input[name="serviceAreas[]"]').off();

    // Handle (check all)
    $('input[name="serviceAreaCheckAll"]').on('change', function(e) {
      var province = $(this).data('province');
      var checked = $(this).prop('checked');
      $('input[name="serviceAreas[]"][data-province="' + province + '"]').prop('checked', checked);
    });

    // Handle service area checkbox - check (check all) if all cities within province have been selected
    $('input[name="serviceAreas[]"]').on('change', function(e) {
      var province = $(this).data('province');
      var allChecked = true;
      $('input[name="serviceAreas[]"][data-province="' + province + '"]').each(function(e) {
        var checked = $(this).prop('checked');
        if (! checked) {
          allChecked = false;
        }
      });
      $('input[name="serviceAreaCheckAll"][data-province="' + province + '"]').prop('checked', allChecked);
    });

    // Check (check all) on load if all cities within province are selected
    this.props.serviceAreaProvinceCheckboxes.forEach(function(province, i) {
      var province = province.province;
      var allChecked = true;
      $('input[name="serviceAreas[]"][data-province="' + province + '"]').each(function(e) {
        var checked = $(this).prop('checked');
        if (! checked) {
          allChecked = false;
        }
        $('input[name="serviceAreaCheckAll"][data-province="' + province + '"]').prop('checked', allChecked);
      });
    });
  },

  componentWillUnmount: function() {
    $('input[name="serviceAreaCheckAll"]').off();
    $('input[name="serviceAreas[]"]').off();
  },

  getFormData: function() {
    var serviceAreas = [];

    $('input[name="serviceAreas[]"]').each(function() {
      if ($(this).prop('checked')) {
        serviceAreas.push($(this).val());
      }
    });

    return serviceAreas;
  },

  render: function() {
    var serviceAreas = [];

    if (this.props.serviceAreaProvinceCheckboxes) {
      this.props.serviceAreaProvinceCheckboxes.forEach(function(province, i) {
        serviceAreas.push(
          <div className="col-sm-12" key={'serviceAreaCheckAll.' + i} style={{marginTop: '10px'}}>
            <div className="form-group" style={{marginBottom: '0px'}}>
              <div className="checkbox" style={{marginBottom: '0px'}}>
                <label>
                  <input type="checkbox" name="serviceAreaCheckAll" data-province={province.province} /> <strong>{province.province}</strong> (check all)
                </label>
              </div>
            </div>
          </div>);

        this.props.serviceAreaCheckboxes.forEach(function(area, i) {
          if (area.province === province.province) {

            var checkbox;

            if ($.inArray(area.id, this.props.checked) !== -1) {
              checkbox = (<input type="checkbox" name="serviceAreas[]" data-province={area.province} value={area.id} defaultChecked/>);
            } else {
              checkbox = (<input type="checkbox" name="serviceAreas[]" data-province={area.province} value={area.id}/>);
            }

            serviceAreas.push(
              <div className="col-sm-4" key={'serviceArea.' + area.id}>
                <div className="form-group" style={{marginBottom: '0px'}}>
                  <div className="checkbox" style={{marginBottom: '0px'}}>
                    <label>
                      {checkbox} {area.city}
                    </label>
                  </div>
                </div>
              </div>);
          }
        }, this);

      }, this);
    }

    return (<div className="row">
      {serviceAreas}
    </div>);
  }

});

module.exports = ServiceAreaCheckboxes;
