var React = require('react');
var canada = require('../../../../js/data/canada');
var usa = require('../../../../js/data/usa');

var StateField = React.createClass({

  getDefaultProps: function() {
    return {
      country: null,
      state: null
    };
  },

  onChange: function(e) {
    this.props.onChange(this.refs.stateField.getDOMNode().value);
  },

  render: function() {
    var canadaOptions = [];
    var usaOptions = [];
    var stateField;

    canada.forEach(function(option, i) {
      canadaOptions.push(<option value={option.value} key={'canada.' + i}>{option.label}</option>);
    });

    usa.forEach(function(option, i) {
      usaOptions.push(<option value={option.value} key={'usa.' + i}>{option.label}</option>);
    });

     if (this.props.country === 'CA') {
      stateField = (
        <select
          ref="stateField"
          id={this.props.id}
          className="form-control"
          defaultValue={this.props.state}
          onChange={this.onChange}
          name="state"
          required>
          <option value="">-- Please select --</option>
          {canadaOptions}
        </select>);
    } else if (this.props.country === 'US') {
      stateField = (
        <select
          ref="stateField"
          id={this.props.id}
          className="form-control"
          defaultValue={this.props.state}
          onChange={this.onChange}
          name="state"
          required>
          <option value="">-- Please select --</option>
          {usaOptions}
        </select>);
    } else {
      stateField = (
        <input
          ref="stateField"
          type="text"
          id={this.props.id}
          className="form-control"
          placeholder="State/Prov"
          defaultValue={this.props.state}
          onChange={this.onChange}
          required />);
    }

    return stateField;
  }
});

module.exports = StateField;
