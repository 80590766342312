var React = require('react/addons');
var Reflux = require('reflux');
var Router = require('react-router');
var AccountActions = require('../actions/AccountActions');
var LoginStore = require('../stores/LoginStore');

function getQueryVariable(variable) {
  var query = window.location.search.substring(1);
  var vars = query.split("&");

  for (var i=0;i<vars.length;i++) {
    var pair = vars[i].split("=");
    if (pair[0] == variable) {
      return pair[1];
    }
  }
  return (false);
}

var Login = React.createClass({

  mixins: [React.addons.LinkedStateMixin, Reflux.ListenerMixin, Router.Navigation],

  getDefaultProps: function() {
    return {
      showLogo: true
    };
  },

  getInitialState: function() {
    return {
      email: '',
      password: '',
      alert: ''
    };
  },

  componentDidMount: function() {
    // Handle form submit
    $('#account-login-form').validator().on('submit', this.handleLoginSubmit);

    // On login
    this.listenTo(LoginStore, this.onLoginStore);
  },

  handleLoginSubmit: function(e) {
    this.setState({alert: ''});

    if (! e.isDefaultPrevented()) {
      e.preventDefault();
      $('#account-login-form button').prop('disabled', true);
      AccountActions.login(this.state.email, this.state.password);
    }
  },

  onLoginStore: function(status) {
    if (status === 'LOGIN_COMPLETED') {
      // Check for referral
      var referral;

      referral = getQueryVariable("ref");

      if (referral) {
        window.location = referral;
      } else {
        window.location = '/account';
      }

    } else if (status === 'LOGIN_FAILED') {
      $('#account-login-form button').prop('disabled', false);

      this.setState({
        alert: 'Invalid username or password.',
        password: ''
      });
    }
  },

  render: function() {
    var logo;
    var alert;

    if (this.props.showLogo) {
      logo = (<a href="/"><img alt="AFEMax™" src="/img/logo.png" height="55" /></a>);
    }

    alert = this.state.alert ? (
      <div className="alert alert-danger" role="alert">
        <i className="fa fa-exclamation-circle"></i> <strong>Error!</strong> {this.state.alert}
      </div>
    ) : '';

    return (
      <div className="container">

        <div className="text-center sigin-welcome">
          {logo}
          <h2>Sign in to continue to AFEMax™</h2>
        </div>

        <div className="card card-container">
          <img className="profile-img-card" src="/img/avatar_2x.png" />
          {alert}
          <form className="form-card" id="account-login-form">
            <div className="form-group">
              <label htmlFor="inputEmail" className="control-label">Email</label>
              <input type="email" id="inputEmail" className="form-control" placeholder="Enter your email" required autofocus data-error="Please enter an email address" valueLink={this.linkState('email')} />
              <div className="help-block with-errors"></div>
            </div>

            <div className="form-group">
              <label htmlFor="inputPassword" className="control-label">Password</label>
              <input type="password" id="inputPassword" className="form-control" placeholder="Password" required  data-error="Please enter a password" valueLink={this.linkState('password')} />
              <div className="help-block with-errors"></div>
            </div>

            <div className="form-group">
              <button className="btn btn-lg btn-primary btn-block" type="submit">Sign in</button>
            </div>
          </form>
          <div className="text-right">
            <a href="/forgot-password" className="forgot-password">
              Forgot password?
            </a>
          </div>
        </div>

        <div className="text-center create-account">
          <a href="/signup">
            Create account
          </a>
        </div>

      </div>
    );
  }

});

module.exports = Login;
